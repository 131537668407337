import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Base from "../component/Base/Base";
import SearchResultCard from "../component/SearchResultCard/SearchResultCard";
// import SearchResultCard from "../component/SearchResultCard/SearchResultCard";
import "../css/SearchResults.css";
import { getPropertyListbyFilter } from "../helper/CoreAPI";

const SearchResults = () => {
  const [searchParams] = useSearchParams();
  const [propertyList, setPropertyList] = useState([]);

  useEffect(() => {
    getPropertyListbyFilter(
      searchParams.get("properrty_sell_rent"),
      searchParams.get("property_type"),
      searchParams.get("city")
    )
      .then((res) => {
        if (res === undefined || res === null) {
          toast.error("Can't connect to server", {
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: false,
            theme: "dark",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          return setPropertyList([]);
        }
        if (res.detail) {
          if (res.detail === "Invalid token.") {
            setPropertyList([]);
            return toast.error("Invalid token. Please Login Again...", {
              position: "top-right",
              autoClose: 2500,
              hideProgressBar: false,
              theme: "dark",
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
          setPropertyList([]);
          return toast.error(res.detail, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            theme: "dark",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        // console.log(res)
        return setPropertyList(res);
      })
      .catch((err) => {
        return toast.error(err, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          theme: "dark",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  }, []);

  return (
    <Base>
      <div className="search-result-page">
        <ToastContainer
          position="top-right"
          autoClose={2000}
          hideProgressBar
          theme="dark"
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />

        {propertyList?.length === 0 && (
          <div className="d-flex justify-content-center no-result-img">
            <img
              alt="result_not_found"
              height="500px"
              src="/images/not_found_poconest_web.jpg"
            />
          </div>
        )}
        <div className="container">
          {propertyList?.map((item) => (
            <SearchResultCard key={item.id} propertyInfo={item} />
          ))}
        </div>
        <br />
      </div>
    </Base>
  );
};

export default SearchResults;

import React, { useState, useEffect, useContext } from "react";
import { ToastContainer, toast } from "react-toastify";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Base from "../../component/Base/Base";
// import Homeimg from "../images/homeimg.jpg";
import { getPropertyListbyWithoutFilter } from "../../helper/CoreAPI";
import { AuthContext } from "../../AuthProvider";
import ResultCard from "../../component/Admin/ResultCard/ResultCard";
import "./Requests.css";

const Requests = () => {
  const { user } = useContext(AuthContext);
  const [propertyList, setPropertyList] = useState([]);
  const [propertyListVerified, setPropertyListVerified] = useState([]);
  const [propertyListInactive, setPropertyListInactive] = useState([]);

  const getPropertyListHelper = () => {
    getPropertyListbyWithoutFilter(user.token)
      .then((res) => {
        if (res === undefined || res === null) {
          toast.error("Can't connect to server", {
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: false,
            theme: "dark",
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
            draggable: true,
          });
          return setPropertyList([]);
        }
        if (res.detail) {
          if (res.detail === "Invalid token.") {
            setPropertyList([]);
            return toast.error("Invalid token. Please Login Again...", {
              position: "top-right",
              autoClose: 2500,
              hideProgressBar: false,
              theme: "dark",
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
          setPropertyList([]);
          return toast.error(res.detail, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            theme: "dark",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        // console.log(res);
        var propertylist = [];
        var propertylistverified = [];
        var propertylistInactive = [];
        res?.forEach((element) => {
          if (element.is_verify && element.is_active) {
            propertylistverified.push(element);
          } else if (element.is_verify && !element.is_active) {
            propertylistInactive.push(element);
          } else {
            propertylist.push(element);
          }
        });
        setPropertyListInactive(propertylistInactive);
        setPropertyListVerified(propertylistverified);
        return setPropertyList(propertylist);
      })
      .catch((err) => {
        return toast.error(err, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          theme: "dark",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  useEffect(() => {
    return getPropertyListHelper();
  }, []);

  return (
    <Base>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar
        theme="dark"
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="container my-4">
        <Tabs
          defaultActiveKey="un-verified"
          id="uncontrolled-tab-example"
          className="mb-3 my-4"
        >
          <Tab eventKey="un-verified" title="Requests">
            {propertyList?.map((item) => (
              <ResultCard
                key={item.id}
                propertyInfo={item}
                user={user}
                getPropertyListHelper={getPropertyListHelper}
              />
            ))}
          </Tab>
          <Tab eventKey="Verified" title="Verified">
            {propertyListVerified?.map((item) => (
              <ResultCard
                key={item.id}
                propertyInfo={item}
                user={user}
                getPropertyListHelper={getPropertyListHelper}
              />
            ))}
          </Tab>
          <Tab eventKey="Inactive" title="Inactive">
            {propertyListInactive?.map((item) => (
              <ResultCard
                key={item.id}
                propertyInfo={item}
                user={user}
                getPropertyListHelper={getPropertyListHelper}
              />
            ))}
          </Tab>
        </Tabs>
      </div>
    </Base>
  );
};

export default Requests;

import React from "react";

const OwnerInformation = ({ data, setData, user }) => {

    // console.log(user)
  // owner_name:"",
  // owner_mobile_no:"",
  // owner_email_id:"",
  // check_ownerInputName: false,
  // check_ownerInputMobileno: false,
  // check_ownerInputEmail: false,

  const handleChangeInputOwnerName = (e) => {
    if (e.target.value.trim().length >= 3) {
      setData({
        ...data,
        owner_name: e.target.value,
        check_ownerInputName: true,
      });
    } else {
      setData({
        ...data,
        owner_name: e.target.value,
        check_ownerInputName: false,
      });
    }
  };

  const mobileNoInputChange = (e) => {
    // console.log(e.target.value.length);
    if (e.target.value.length === 10) {
      setData({
        ...data,
        owner_mobile_no: e.target.value,
        check_ownerInputMobileno: true,
      });
    } else {
      setData({
        ...data,
        owner_mobile_no: e.target.value,
        check_ownerInputMobileno: false,
      });
    }
  };

  const emailInputChange = (e) => {
    const reg =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (e.target.value.length >= 3 && reg.test(e.target.value)) {
      setData({
        ...data,
        owner_email_id: e.target.value,
        check_ownerInputEmail: true,
      });
    } else {
      setData({
        ...data,
        owner_email_id: e.target.value,
        check_ownerInputEmail: false,
      });
    }
  };

  const handleChangeInputIAmOwner = (e) => {
        // owner_name:"",
  // owner_mobile_no:"",
  // owner_email_id:"",
  // check_ownerInputName: false,
  // check_ownerInputMobileno: false,
  // check_ownerInputEmail: false,
    setData({
        ...data,
        owner_name: user.first_name+" "+user.last_name,
        owner_mobile_no: user.mobile_no,
        owner_email_id: user.email,
        check_ownerInputName: true,
        check_ownerInputMobileno: true,
        check_ownerInputEmail: true,

      });
  };

  return (
    <>
      <h4>Owner's Information</h4>
      <div class="col-sm-12">
        <label for="firstName" class="form-label">
          Owner's Name :
        </label>
        <input
          className={
            data.check_ownerInputName
              ? "form-control is-valid"
              : "form-control is-invalid"
          }
          type="text"
          name="property-owner_name"
          placeholder="Eg. Abcd xyz"
          id="property-owner_name"
          value={data.owner_name}
          onChange={(e) => handleChangeInputOwnerName(e)}
          required
        />
        {data.check_ownerInputName ? null : (
          <div
            id="validationServerNameFeedback"
            className="invalid-feedback text-danger p-1"
          >
            Property Name must not be null.
          </div>
        )}
      </div>
      <div class="col-sm-6">
        <label for="firstName" class="form-label">
          Owner's Email :
        </label>
        <input
          className={
            data.check_ownerInputEmail
              ? "form-control is-valid"
              : "form-control is-invalid"
          }
          type="text"
          name="property-owner_email_id"
          placeholder="Eg. Abcd xyz"
          id="property-owner_email_id"
          value={data.owner_email_id}
          onChange={(e) => emailInputChange(e)}
          required
        />
        {data.check_ownerInputEmail ? null : (
          <div
            id="validationServerNameFeedback"
            className="invalid-feedback text-danger p-1"
          >
            Please enter a valid email address.
          </div>
        )}
      </div>
      <div class="col-sm-6">
        <label for="firstName" class="form-label">
          Owner's Mobile No :
        </label>
        <input
          className={
            data.check_ownerInputMobileno
              ? "form-control is-valid"
              : "form-control is-invalid"
          }
          type="number"
          name="property-owner_mobile_no"
          placeholder="Eg. Abcd xyz"
          id="property-owner_mobile_no"
          value={data.owner_mobile_no}
          onChange={(e) => mobileNoInputChange(e)}
          required
        />
        {data.check_ownerInputMobileno ? null : (
          <div
            id="validationServerNameFeedback"
            className="invalid-feedback text-danger p-1"
          >
            Mobile No must be 10 characters.
          </div>
        )}
      </div>
      <div class="col-sm-12">
        <input
          class="form-check-input"
          type="checkbox"
          value=""
          id="flexCheckDefault"
          onClick={() => handleChangeInputIAmOwner()}
        />
        <label class="form-check-label" for="flexCheckDefault">
          &nbsp;&nbsp; I am Owner
        </label>
      </div>
    </>
  );
};

export default OwnerInformation;

/* eslint-disable no-console */
import React, { createContext, useState } from "react";
import { toast } from "react-toastify";
import { signin, signout, signup } from "./helper/IndexAuth";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        login: async (email, password) => {
          toast.info("Please wait login process", {
            position: "top-right",
            autoClose: 1500,
            hideProgressBar: false,
            theme: "dark",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          signin(email, password)
            .then((res) => {
              if (res.error) {
                console.log(res);
                return toast.error(res.error, {
                  position: "top-right",
                  autoClose: 3000,
                  hideProgressBar: false,
                  theme: "dark",
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }
              if (typeof window !== undefined) {
                localStorage.setItem("authenticationData", JSON.stringify(res));
                return window.location.reload();
              }
              return null;
            })
            .catch((err) => {
              if (err.message === "Failed to fetch") {
                return toast.error(
                  "Check Your Internet connection or Server is not working.",
                  {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    theme: "dark",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  }
                );
              }
              return toast.error(err.message, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                theme: "dark",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            });
        },
        register: async (first_name, last_name, email, password, mobileno) => {
          toast.info("Please wait register in process", {
            position: "top-right",
            autoClose: 1500,
            hideProgressBar: false,
            theme: "dark",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          signup({ first_name, last_name, email, password, mobileno })
            .then((res) => {
              // console.log("res in signup", res);
              if (res.email !== email) {
                for (const emailerror of res.email) {
                  toast.error(emailerror, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    theme: "dark",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                }
                return null
              }
              if (res.error) {
                // console.log(res);
                return toast.error(res.error, {
                  position: "top-right",
                  autoClose: 3000,
                  hideProgressBar: false,
                  theme: "dark",
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }
              toast.success(
                "Registration done successfully. Please Login with email",
                {
                  position: "top-right",
                  autoClose: 3000,
                  hideProgressBar: false,
                  theme: "dark",
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                }
              );
              setTimeout(() => {
                return window.location.href ="/user/login"
              }, 3000);
              // if (typeof window !== undefined) {
              //   localStorage.setItem("authenticationData", JSON.stringify(res));
              //   return window.location.reload();
              // }
            })
            .catch((err) => {
              if (err.message === "Failed to fetch") {
                return toast.error(
                  "Check Your Internet connection or Server is not working.",
                  {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    theme: "dark",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  }
                );
              }
              return toast.error(err.message, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                theme: "dark",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            });
        },
        logout: async () => {
          try {
            signout();
            window.location.reload();
          } catch (e) {
            console.log(e);
          }
        },
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

import React, { useState, useContext } from "react";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import { FaLock } from "react-icons/fa";
import { ToastContainer } from "react-toastify";
import { AuthContext } from "../AuthProvider";
import "../css/LoginPage.css";

const LoginPage = () => {
  const { login } = useContext(AuthContext);
  const [data, setData] = useState({
    email: "",
    password: "",
    check_emailInputChange: false,
    check_passwordInputChange: false,
    secureTextEntry: false,
  });

  const emailInputChange = (e) => {
    let reg =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (e.target.value.trim().length >= 3 && reg.test(e.target.value)) {
      setData({
        ...data,
        email: e.target.value,
        check_emailInputChange: true,
      });
    } else {
      setData({
        ...data,
        email: e.target.value,
        check_emailInputChange: false,
      });
    }
  };

  const passwordInputChange = (e) => {
    if (e.target.value.trim().length >= 3) {
      setData({
        ...data,
        password: e.target.value,
        check_passwordInputChange: true,
      });
    } else {
      setData({
        ...data,
        password: e.target.value,
        check_passwordInputChange: false,
      });
    }
  };

  // const updateSecureTextEntry = () => {
  //   setData({
  //     ...data,
  //     secureTextEntry: !data.secureTextEntry,
  //   });
  // };

  return (
    <div className="login-page">
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar
        theme="dark"
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="login-page-form">
        <form className="login-form">
          <div className="login-lock">
            <FaLock size={50} id="login-lock" />
          </div>
          <div className="login-input-container">
            <label>Email </label>
            <br />
            <input
              type="email"
              name="uname"
              value={data.email}
              className={
                data.check_emailInputChange
                  ? "form-control is-valid"
                  : "form-control is-invalid"
              }
              onChange={(text) => emailInputChange(text)}
              placeholder="xyz@gmail.com"
              required

            />
            {data.check_emailInputChange ? null : (
              <div
                id="validationServerNameFeedback"
                className="invalid-feedback text-black p-1"
              >
                Please enter a valid email address.
              </div>
            )}
          </div>
          <div className="login-input-container">
            <label>Password </label>
            <br />
            <input
              type={data.secureTextEntry ? "text" : "password"}
              value={data.password}
              className={
                data.check_passwordInputChange
                  ? "form-control is-valid"
                  : "form-control is-invalid"
              }
              onChange={(text) => passwordInputChange(text)}
              name="pass"
              placeholder="Password"
              required
            />
            {/* <button type="button" onClick={() => updateSecureTextEntry()} className="btn btn-outline-* d-flex justify-content-end">
                <AiFillEyeInvisible size={20} />
              </button> */}

            {data.check_passwordInputChange ? null : (
              <div
                id="validationServerNameFeedback"
                className="invalid-feedback text-black p-1"
              >
                Password must be 3 characters long.
              </div>
            )}
          </div>
          <div className="login-page-login-btn">
            <Button
              id="login-page-login-btn"
              onClick={() => login(data.email, data.password)}
              disabled={
                !data.check_emailInputChange || !data.check_passwordInputChange
              }
            >
              Login
            </Button>
          </div>
          <div className="login-page-login-btn">
            <Link to="/user/register">
              <Button id="login-page-new-btn">New account</Button>
            </Link>
          </div>
          <div className="login-page-login-btn">
            <Link to="/user/forgetpassword">
              Forget Password
            </Link>
          </div>

        </form>
      </div>
    </div>
  );
};

export default LoginPage;

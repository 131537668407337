import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { FaEye, FaRupeeSign } from "react-icons/fa";
import { MdLocationPin } from "react-icons/md";
import { BsFillTelephoneOutboundFill } from "react-icons/bs";
import { getDummyImageListHelper } from "../../helper/CoreAPI";
import "./SearchResultCardv2.css";

const SearchResultCard = ({ propertyInfo }) => {
  const [dummyImage, setDummyImage] = useState({});

  const getDummyImageList = () => {
    getDummyImageListHelper()
      .then((res) => {
        if (res === undefined || res === null) {
          toast.error("Can't Find Dummy Image List", {
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: false,
            theme: "dark",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          return setDummyImage({});
        }
        // console.log("res pass", res[propertyInfo.property_type.sub_type]);
        return setDummyImage(res);
      })
      .catch((err) => {
        return toast.error(err, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          theme: "dark",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  useEffect(() => {
    return getDummyImageList();
  }, []);

  // console.log(propertyInfo.property_type.sub_type);
  return (
    <div className="card mb-3 my-4" style={{ width: "100%" }}>
      <div className="row g-0">
        <div className="col-md-3">
          {propertyInfo?.image !== null && (
            <img
              height={250}
              width="100%"
              src={propertyInfo.image}
              className="img-fluid rounded-start img-height-250"
              alt="..."
            />
          )}
          {propertyInfo?.image === null && (
            <img
              height={250}
              width="100%"
              style={{ objectFit: "cover" }}
              src={dummyImage[propertyInfo.property_type.sub_type]}
              alt="..."
            />
          )}
          <div className="position-absolute top-0 m-3 start-0 badges-bag">
            <span className="top-3 start-0 translate-middle badge rounded-pill bg-* badge-image-count">
              {propertyInfo.propertyImages?.length + 1}
            </span>

            {propertyInfo.properrty_sell_rent === "Sell" ? (
              <span className="top-3 start-1 translate-middle badge rounded-pill bg-* badge-sell">
                {propertyInfo.properrty_sell_rent}
              </span>
            ) : (
              <span className=" top-3 start-1 translate-middle badge rounded-pill bg-* badge-rent">
                {propertyInfo.properrty_sell_rent}
              </span>
            )}
            {propertyInfo?.image === null && (
              <span className="top-3 start-0 translate-middle badge rounded-pill bg-light badge-dummy-image">
                Dummy Image
              </span>
            )}
          </div>
        </div>
        <div className="col-md-8">
          <div className="card-body">
            <h5 className="card-title">{propertyInfo.name}</h5>
            <p className="card-text">
              {propertyInfo.address_no?.length >= 1 && propertyInfo.address_no}
              ,&nbsp;
              {propertyInfo.floor_number?.length >= 1 && (
                <>{propertyInfo.floor_number},</>
              )}
              &nbsp;
              {propertyInfo.address_line1.substring(0,40)}...,&nbsp;
            </p>
            <div className="row">
              <div className="col-lg-4">
                <p className="card-text">
                  City:&nbsp;<strong>{propertyInfo.city}</strong>
                </p>
              </div>
              <div className="col-lg-4">
                <p className="card-text">
                  District:&nbsp;<strong>{propertyInfo.district}</strong>
                </p>
              </div>
              <div className="col-lg-4">
                <p className="card-text">
                  Area:&nbsp;<strong>{propertyInfo.size}&nbsp;sq ft</strong>
                </p>
              </div>
            </div>
            <div className="row">
              {propertyInfo?.avg_price !== null &&
                propertyInfo?.avg_price !== "" && (
                  <div className="col-lg-4">
                    <p className="card-text">
                      Rate:&nbsp;
                      <strong>{propertyInfo.avg_price}&nbsp;per sq ft</strong>
                    </p>
                  </div>
                )}
              {propertyInfo?.rate_price !== null &&
                propertyInfo?.rate_price !== "" && (
                  <div className="col-lg-4">
                    <p className="card-text">
                      Price:&nbsp;
                      <span className="span-price">
                        <strong>
                          <FaRupeeSign />
                          {propertyInfo.rate_price}
                        </strong>
                      </span>
                    </p>
                  </div>
                )}
              {propertyInfo?.rent_price !== null &&
                propertyInfo?.rent_price !== "" && (
                  <div className="col-lg-4">
                    <p className="card-text">
                      Rent Price:&nbsp;
                      <span className="span-price">
                        <strong>
                          {" "}
                          <FaRupeeSign />
                          {propertyInfo.rent_price}&nbsp;
                        </strong>
                      </span>
                    </p>
                  </div>
                )}
              {propertyInfo?.security_price !== null &&
                propertyInfo?.security_price !== "" && (
                  <div className="col-lg-4">
                    <p className="card-text">
                      Security Price:&nbsp;
                      <strong>
                        {propertyInfo.security_price}&nbsp;per sq ft
                      </strong>
                    </p>
                  </div>
                )}
            </div>
          </div>
        </div>
        <div className="col-md-1">
          <div className="d-flex justify-content-center">
            <div className="row row-custom-buttons">
              <div className="col col-lg-12">
                <Link to={`/property/${propertyInfo.name}/${propertyInfo.id}/`}>
                  <button className="btn" id="view-detail-btn">
                    <FaEye />
                  </button>
                </Link>
              </div>
              <div className="col col-lg-12">
                {propertyInfo.google_address?.length >= 1 && (
                  <a href={propertyInfo.google_address}>
                    <button className="btn" id="get-location-btn">
                      <MdLocationPin />
                    </button>
                  </a>
                )}
              </div>
              <div className="col col-lg-12">
                <a
                  href={`tel:+918605399330`}
                  className="flex-1"
                >
                  <button className="btn" id="search-contact-btn">
                    <BsFillTelephoneOutboundFill />
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchResultCard;

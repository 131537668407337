import React from "react";
import { Link } from "react-router-dom";
import { FaEye, FaRupeeSign } from "react-icons/fa";
import { MdLocationPin } from "react-icons/md";
import { BsFillTelephoneOutboundFill } from "react-icons/bs";
import "./VehicleResultCard.css";

const VehicleResultCard = ({vehicleInfo}) => {
  return (
    <div className="card mb-3 my-2" style={{ width: "100%" }}>
      <div className="row g-0">
        <div className="col-md-3">
            <img
              height={250}
              width="100%"
              src={vehicleInfo.image}
              className="img-fluid rounded-start img-height-250"
              alt="..."
            />
          <div className="position-absolute top-0 m-3 start-0 badges-bag">
            <span className="top-3 start-0 translate-middle badge rounded-pill bg-* badge-image-count">
              {vehicleInfo.VehicleImages?.length + 1}
            </span>

            {vehicleInfo.vehicle_sell_rent === "Sell" ? (
              <span className="top-3 start-1 translate-middle badge rounded-pill bg-* badge-sell">
                {vehicleInfo.vehicle_sell_rent}
              </span>
            ) : (
              <span className=" top-3 start-1 translate-middle badge rounded-pill bg-* badge-rent">
                {vehicleInfo.vehicle_sell_rent}
              </span>
            )}
          </div>
        </div>
        <div className="col-md-8">
          <div className="card-body">
            <h5 className="card-title">{vehicleInfo.company_name}</h5>
            <p className="card-text">
              {vehicleInfo.address_line1.substring(0,40)}...,&nbsp;
            </p>
            <div className="row">
              <div className="col-lg-4">
                <p className="card-text">
                  City:&nbsp;<strong>{vehicleInfo.city}</strong>
                </p>
              </div>
              <div className="col-lg-4">
                <p className="card-text">
                  District:&nbsp;<strong>{vehicleInfo.district}</strong>
                </p>
              </div>
              <div className="col-lg-4">
                <p className="card-text">
                Age of Vehicle:&nbsp;<strong>{vehicleInfo.age_of_vehicle}&nbsp;sq ft</strong>
                </p>
              </div>
            </div>
            <div className="row">
              {vehicleInfo?.km_run !== null &&
                vehicleInfo?.km_run !== "" && (
                  <div className="col-lg-4">
                    <p className="card-text">
                     KM Running:&nbsp;
                      <strong>{vehicleInfo.km_run}</strong>
                    </p>
                  </div>
                )}
              {vehicleInfo?.expected_price !== null &&
                vehicleInfo?.expected_price !== "" && (
                  <div className="col-lg-4">
                    <p className="card-text">
                    Expected Price:&nbsp;
                      <span className="span-price">
                        <strong>
                          <FaRupeeSign />
                          {vehicleInfo.expected_price}
                        </strong>
                      </span>
                    </p>
                  </div>
                )}
            </div>
          </div>
        </div>
        <div className="col-md-1">
          <div className="d-flex justify-content-center">
            <div className="row row-custom-buttons">
              <div className="col col-lg-12">
                <Link to={`/vehicle/${vehicleInfo.company_name}/${vehicleInfo.id}/`}>
                  <button className="btn" id="view-detail-btn">
                    <FaEye />
                  </button>
                </Link>
              </div>
              <div className="col col-lg-12">
                {vehicleInfo.google_address?.length >= 1 && (
                  <a href={vehicleInfo.google_address}>
                    <button className="btn" id="get-location-btn">
                      <MdLocationPin />
                    </button>
                  </a>
                )}
              </div>
              <div className="col col-lg-12">
                <a
                  href={`tel:+918605399330`}
                  className="flex-1"
                >
                  <button className="btn" id="search-contact-btn">
                    <BsFillTelephoneOutboundFill />
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default VehicleResultCard
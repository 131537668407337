import API from '../Backend';

export const PropertyVerifyActiveHelpler = async (id,is_verify, is_active, token) => {
    // console.log(token)
    const formData = new FormData();
    formData.append('id', id);
    formData.append('is_verify', is_verify);
    formData.append('is_active', is_active);

    const response = await fetch(`${API}property/verify/active/`, {
      method: 'POST',
      headers: {
        authorization: `Token ${token}`,
      },
      body: formData,
    });
    return response.json();
  };

  export const VehicleVerifyActiveHelpler = async (id,is_verify, is_active, token) => {
    // console.log(token)
    const formData = new FormData();
    formData.append('id', id);
    formData.append('is_verify', is_verify);
    formData.append('is_active', is_active);

    const response = await fetch(`${API}vehicle/verify/active/`, {
      method: 'POST',
      headers: {
        authorization: `Token ${token}`,
      },
      body: formData,
    });
    return response.json();
  };
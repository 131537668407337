import API from "../Backend";

export const getPropertyTypeList = () => {
  return fetch(`${API}property/type/`, {
    method: "GET",
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      return console.log(err);
    });
};

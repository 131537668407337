import API from "../Backend";

export const sendOtpSms = async (owner_mobile_no, token) => {
  const formData = new FormData();
  formData.append("mobileNo", owner_mobile_no);

  const response = await fetch(`${API}otp/mobileno/send/`, {
    method: "POST",
    headers: {
      // 'Content-Type': 'multipart/form-data',
      authorization: `Token ${token}`,
    },
    body: formData,
  });
  return response.json();
};

export const verifyOtpSms = async (owner_mobile_no, otp, token) => {
  const formData = new FormData();
  formData.append("mobileNo", owner_mobile_no);
  formData.append("otp", otp);

  const response = await fetch(`${API}otp/mobileno/verify/`, {
    method: "POST",
    headers: {
      // 'Content-Type': 'multipart/form-data',
      authorization: `Token ${token}`,
    },
    body: formData,
  });
  return response.json();
};

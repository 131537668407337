import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { FaRupeeSign } from "react-icons/fa";
import { BsFillTelephoneOutboundFill } from "react-icons/bs";
import Modal from "react-bootstrap/Modal";
import { MdLocationPin } from "react-icons/md";
import Carousel from "react-bootstrap/Carousel";
import Base from "../component/Base/Base";
import { getDummyImageListHelper, getPropertyDetails } from "../helper/CoreAPI";
import { AuthContext } from "../AuthProvider";
import "../css/PropertyDetail.css";

const PropertyDetail = () => {
  let { id } = useParams();
  const { user } = useContext(AuthContext);

  const [propertyInfo, setPropertyInfo] = useState({});
  const [propertyImages, setPropertyImages] = useState([]);
  const [dummyImage, setDummyImage] = useState({});
  const [modalView7_12, setModalView7_12] = useState(false);
  const [imageSrc7_12, setImageSrc7_12] = useState(null);

  const handleOpenModal_7_12 = (src) => {
    setImageSrc7_12(src);
    setModalView7_12(true);
  };

  const handleCloseModal_7_12 = () => {
    setModalView7_12(false);
    setImageSrc7_12(null);
  };

  const getDummyImageList = () => {
    // console.log("pass dummy image")
    getDummyImageListHelper()
      .then((res) => {
        if (res === undefined || res === null) {
          toast.error("Can't Find Dummy Image List", {
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: false,
            theme: "dark",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          return setDummyImage({});
        }
        // console.log("res pass", res[propertyInfo.property_type.sub_type]);
        return setDummyImage(res);
      })
      .catch((err) => {
        return toast.error(err, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          theme: "dark",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  const getPropertyDetailsFunction = () => {
    getPropertyDetails(id)
      .then((res) => {
        if (res === undefined || res === null) {
          toast.error("Can't connect to server", {
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: false,
            theme: "dark",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          return setPropertyInfo([]);
        }
        if (res.detail) {
          if (res.detail === "Invalid token.") {
            setPropertyInfo([]);
            return toast.error("Invalid token. Please Login Again...", {
              position: "top-right",
              autoClose: 2500,
              hideProgressBar: false,
              theme: "dark",
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
          setPropertyInfo([]);
          return toast.error(res.detail, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            theme: "dark",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        // console.log(res)
        var image1 = [];
        image1.push(res.image);
        if (res.propertyImages?.length !== 0) {
          res.propertyImages?.map((item) => image1.push(item.image));
        }
        // console.log(image1);
        //        console.log("pass res",res.property_type.id)
        // console.log(image1);
        // res.propertyImages
        setPropertyImages(image1);
        //        console.log(res);
        return setPropertyInfo(res);
      })
      .catch((err) => {
        return toast.error(err, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          theme: "dark",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  useEffect(() => {
    getDummyImageList();
    return getPropertyDetailsFunction();
  }, []);

  return (
    <Base>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar
        theme="dark"
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Modal show={modalView7_12} fullscreen onHide={handleCloseModal_7_12}>
        <Modal.Header closeButton>
          <Modal.Title>7/12 Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img alt="7/12 doc" src={imageSrc7_12} width={"100%"} />
        </Modal.Body>
      </Modal>
      <div className="container my-5">
        <div className="row">
          <div className="col-lg-6">
            {propertyImages[0] !== null && (
              <Carousel>
                {propertyImages.map((img1, index) => (
                  <Carousel.Item key={index}>
                    <img
                      className="d-block w-100"
                      src={img1}
                      alt="First slide"
                    />
                  </Carousel.Item>
                ))}
              </Carousel>
            )}
            {propertyImages.length === 1 && propertyImages[0] === null && (
              <Carousel>
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src={dummyImage[propertyInfo.property_type?.sub_type]}
                    alt="First slide"
                  />
                  {/* <Carousel.Caption>
                                  <h3>First slide label</h3>
                                  <p>
                                    Nulla vitae elit libero, a pharetra augue mollis
                                    interdum.
                                  </p>
                                </Carousel.Caption> */}
                </Carousel.Item>
              </Carousel>
            )}
            <div className="row">
              {propertyInfo.propertyDocumentImage?.map((propertydoc) => (
                <div className="col-lg-4 imagecontainer" key={propertydoc.id}>
                  <img
                    className="uploadimage"
                    alt="doc7/12"
                    src={propertydoc.image}
                    height={250}
                    width={250}
                  />
                  <div className="middle">
                    <button
                      type="button"
                      onClick={() => handleOpenModal_7_12(propertydoc.image)}
                      className="btn btn-* m-2"
                      style={{ backgroundColor: "#02B290" }}
                    >
                      View
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="col-lg-6">
            <div className="row">
              <div className="col-lg-12">
                <p id="property-detail-page-view-heading">
                  <strong>{propertyInfo.name}</strong>
                </p>
              </div>
              <div className="col-lg-12">
                {user?.user?.is_staff && (
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">Owner details</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Owner Name :</td>
                        <td>{propertyInfo.owner_name} </td>
                      </tr>
                      <tr>
                        <td>Owner Mobile No :</td>
                        <td>{propertyInfo.owner_MobileNo} </td>
                      </tr>
                      <tr>
                        <td>Owner Email :</td>
                        <td>{propertyInfo.owner_email} </td>
                      </tr>
                    </tbody>
                  </table>
                )}
              </div>
              <div className="col-lg-12">
                <table className="table" style={{ width: "100%" }}>
                  <thead>
                    <tr>
                      <th scope="col" colSpan={2}>
                        Property details
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Address</td>
                      <td>
                        {propertyInfo.address_no?.length >= 1 &&
                          propertyInfo.address_no}
                        ,&nbsp;
                        {propertyInfo.floor_number?.length >= 1 && (
                          <>{propertyInfo.floor_number},</>
                        )}
                        &nbsp;
                        {propertyInfo.address_line1}
                        {/* {propertyInfo.address_line2?.length >= 1 && (
                          <>({propertyInfo.address_line2}),</>
                        )}
                        &nbsp;
                        {propertyInfo.city},&nbsp;
                        {propertyInfo.district},&nbsp;
                        {propertyInfo.state},&nbsp;
                        {propertyInfo.country}&nbsp; */}
                      </td>
                    </tr>
                    <tr>
                      <td>City :</td>
                      <td>{propertyInfo.city} </td>
                    </tr>
                    <tr>
                      <td>District :</td>
                      <td>{propertyInfo.district} </td>
                    </tr>
                    <tr>
                      <td> State : </td>
                      <td>{propertyInfo.state}</td>
                    </tr>
                    <tr>
                      <td>Area : </td>
                      <td>
                        {propertyInfo.size}&nbsp;&nbsp;
                        <strong>Sq.ft</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>Per sq.ft rate : </td>
                      <td>
                        {propertyInfo?.avg_price !== null &&
                          propertyInfo?.avg_price !== "" && (
                            <p className="flex-1" id="search-result-card-rate">
                              {" "}
                              <span id="span-id">{propertyInfo.avg_price}</span>
                              &nbsp;
                              <strong>Per sq ft</strong>
                            </p>
                          )}
                      </td>
                    </tr>
                    {propertyInfo?.rate_price !== null &&
                      propertyInfo?.rate_price !== "" && (
                        <tr>
                          <td>Price :</td>
                          <td>
                            <span id="span-id-price">
                              <FaRupeeSign /> {propertyInfo.rate_price}
                            </span>
                          </td>
                        </tr>
                      )}
                    {propertyInfo?.rent_price !== null &&
                      propertyInfo?.rent_price !== "" && (
                        <tr>
                          <td> Rent Price</td>
                          <td>
                            {" "}
                            <span id="span-id-price">
                              {" "}
                              <FaRupeeSign /> {propertyInfo.rent_price}
                            </span>
                          </td>
                        </tr>
                      )}
                    {propertyInfo?.security_price !== null &&
                      propertyInfo?.security_price !== "" && (
                        <tr>
                          <td>Security Price :</td>
                          <td>
                            {" "}
                            <span id="span-id">
                              {" "}
                              <FaRupeeSign /> {propertyInfo.security_price}{" "}
                            </span>
                          </td>
                        </tr>
                      )}
                    {propertyInfo.age_of_property?.length >= 1 && (
                      <tr>
                        <td>Age of Property :</td>
                        <td> {propertyInfo?.age_of_property}</td>
                      </tr>
                    )}
                    {propertyInfo.geo_location?.length >= 1 && (
                      <tr>
                        <td>Geo-Coordinates :</td>
                        <td>{propertyInfo?.geo_location}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="col-lg-12">
                {propertyInfo.rich_text_description?.length >= 1 && (
                  <div className="mt-4">
                    <table className="table">
                      <thead>
                        <th>Description</th>
                      </thead>
                      <tbody>
                        <td>
                          <p
                            id="search-result-card-rate"
                            dangerouslySetInnerHTML={{
                              __html: propertyInfo?.rich_text_description,
                            }}
                            className="editor"
                          ></p>
                        </td>
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* third part */}
      <div className="float" id="btn-buttons">
        {/* <Link to="/propertydetailview">
                <button className="btn" id="view-detail-btn">
                  <FaEye />
                </button>
              </Link> */}
        {propertyInfo.google_address?.length >= 1 && (
          <a href={propertyInfo.google_address}>
            <button className="btn my-float" id="get-location-btn">
              <MdLocationPin className="float-icon-size" />
            </button>
          </a>
        )}

        <a href={`tel:+918605399330`} className="flex-1">
          <button className="btn" id="search-contact-btn">
            <BsFillTelephoneOutboundFill className="float-icon-size" />
          </button>
        </a>
      </div>
    </Base>
  );
};

export default PropertyDetail;

import React, { useContext, useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { AuthContext } from "./AuthProvider";
import Home from "./pages/Home";
import SearchResults from "./pages/SearchResults";
import LoginPage from "./pages/LoginPage";
import RegisterPage from "./pages/RegisterPage";
import PropertyDetail from "./pages/PropertyDetail";
import PropertyUploadForm from "./pages/PropertyUploadForm";
import { isAuthenticated } from "./helper/IndexAuth";
import Requests from "./pages/adminpages/Requests";
import VehicleRequests from "./pages/adminpages/VehicleRequests"
import ForgetPassword from "./pages/ForgetPassword";
import UploadVehicle from "./pages/UploadVehicle";
import VehicleDetails from "./pages/VehicleDetails";
import VehicleHome from "./pages/VehicleHome";
import ListAllProperty from "./pages/ListAllProperty";

const IndexRoutes = () => {
  const { user, setUser } = useContext(AuthContext);

  const onAuthStateChanged = () => {
    const user = isAuthenticated();
    if (user) {
      setUser(user);
    }
  };

  useEffect(() => {
    return onAuthStateChanged(); // unsubscribe on unmount
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/searchresults" element={<SearchResults />} />
        <Route path="/property/:name/:id" element={<PropertyDetail />} />
        <Route path="/property" element={<ListAllProperty />} />
        <Route path="/vehicle/:name/:id" element={<VehicleDetails />} />
        <Route path="/vehicle" element={<VehicleHome />} />

        {user ? (
          <>
            <Route path="/user/login" element={<Navigate replace to="/" />} />
            <Route
              path="/user/register"
              element={<Navigate replace to="/" />}
            />
            <Route path="/upload/property" element={<PropertyUploadForm />} />
            <Route path="/upload/vehicle" element={<UploadVehicle />} />
            {user.user.is_staff && (
              <>
                <Route path="/admin/requests" element={<Requests />} />
                <Route path="/admin/vehicle-requests" element={<VehicleRequests />} />
              </>
            )}
            {/* <Route path="/propertyuploadform_temp" element={<PropertyUploadForm />} /> */}
          </>
        ) : (
          <>
            <Route path="/" element={<Navigate replace to="/user/login" />} />
            <Route path="/user/login" element={<LoginPage />} />
            <Route path="/user/forgetpassword" element={<ForgetPassword />} />
            <Route path="/user/register" element={<RegisterPage />} />
            <Route
              path="/propertyuploadform"
              element={<Navigate replace to="/user/login" />}
            />
          </>
        )}
      </Routes>
    </BrowserRouter>
  );
};

export default IndexRoutes;

const editorConfiguration = {
  toolbar: [
    "heading",
    "bold",
    "italic",
    "blockQuote",
    "link",
    "numberedList",
    "bulletedList",
    "insertTable",
    "undo",
    "redo",
  ],
};

export default editorConfiguration;

import React from "react";
import Spinner from "react-bootstrap/Spinner";
import './LoadingModal.css';

const LoadingModal = () => {
  return (
    <div className="load-modal">
      <div className="load-spinner">
        <Spinner animation="border" />
        {/* <div
          className="spinner-border"
          style={{ width: '3rem', height: '3rem' }}
          role="status"
        >
          <span className="visually-hidden">Loading...</span>
        </div> */}
      </div>
    </div>
  );
};

export default LoadingModal;

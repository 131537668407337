import API from "../Backend";

export const getPropertyList = () => {
  return fetch(`${API}property/property/`, {
    method: "GET",
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      return console.log(err);
    });
};

export const getVehicleList = () => {
  return fetch(`${API}vehicle/vehicle/`, {
    method: "GET",
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      return console.log(err);
    });
};

export const getVehicleListFilter = () => {
  return fetch(`${API}vehicle/vehicle/?vehicle_type=&city=&is_active=true&is_verify=true`, {
    method: "GET",
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      return console.log(err);
    });
};

export const getPropertyListbyWithoutFilter = (token) => {
  return fetch(`${API}property/without_filter/`, {
    method: "GET",
    headers: {
      authorization: `Token ${token}`,
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      return console.log(err);
    });
};


export const getPropertyListbyFilter = (properrty_sell_rent, id, city) => {
  if(city=== null || city === '')
  {
    return fetch(`${API}property/property?properrty_sell_rent=${properrty_sell_rent}&property_type=${id}&city=`, {
      method: "GET",
    })
      .then((response) => {
        return response.json();
      })
      .catch((err) => {
        return console.log(err);
      });    
  }
  return fetch(`${API}property/property?property_type=${id}&city=${city}`, {
    method: "GET",
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      return console.log(err);
    });
};

export const getPropertyDetails = (id) => {
  return fetch(`${API}property/property/${id}/`, {
    method: "GET",
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      return console.log(err);
    });
};

export const getVehicleDetails = (id) => {
  return fetch(`${API}vehicle/vehicle/${id}/`, {
    method: "GET",
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      return console.log(err);
    });
};


export const getStateListHelper = () => {
  return fetch(`/data/state.json`, {
    method: "GET",
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      return console.log(err);
    });
}

export const getDummyImageListHelper = () => {
  return fetch(`/data/dummy-image-list.json`, {
    method: "GET",
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      return console.log(err);
    });
}


export const deletePropertyHelper = (id, token) => {
  // console.log("delete",id)
  return fetch(`${API}property/without_filter/${id}/`, {
    method: "DELETE",
    headers: {
      // 'Content-Type': 'multipart/form-data',
      authorization: `Token ${token}`,
    },
  })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return console.log(err);
    });
};

export const deleteVehicleHelper = (id, token) => {
  // console.log("delete",id)
  return fetch(`${API}vehicle/vehicle/${id}/`, {
    method: "DELETE",
    headers: {
      // 'Content-Type': 'multipart/form-data',
      authorization: `Token ${token}`,
    },
  })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return console.log(err);
    });
};
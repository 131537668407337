import API from "../Backend";

export const NewProperty = async (data, userId ,token) => {
  const {
    property_rent_sell,
    name,
    owner_name,
    owner_mobile_no,
    owner_email_id,
    property_type,
    image,
    document_image,
    floor_number,
    address_no,
    address_line1,
    address_line2,
    city,
    district,
    state,
    country,
    geo_location,
    google_address,
    description,
    rich_text_description,
    bhk_type,
    size,
    rate_price,
    avg_price,
    rent_price,
    security_price,
    possession_status,
    brokerage,
    maintenance,
    furnishing,
    age_of_property,
    parking,
  } = data;
  console.log(property_type);
  console.log(userId);
  // console.log('id', patientInfo.id);
  const formData = new FormData();
  formData.append("property_rent_sell", property_rent_sell);
  formData.append("name", name);
  formData.append("owner_name", owner_name);
  formData.append("owner_mobile_no", owner_mobile_no);
  formData.append("owner_email_id", owner_email_id);
  formData.append("user", userId);
  formData.append("property_type", property_type);
  formData.append("floor_number", floor_number);
  formData.append("address_no", address_no);
  formData.append("address_line1", address_line1);
  formData.append("address_line2", address_line2);
  formData.append("city", city);
  formData.append("district", district);
  formData.append("state", state);
  formData.append("country", country);
  formData.append("geo_location", geo_location);
  formData.append("google_address", google_address);
  formData.append("description", description);
  formData.append("rich_text_description", rich_text_description);
  formData.append("bhk_type", bhk_type);
  formData.append("size", size);
  formData.append("rate_price", rate_price);
  formData.append("avg_price", avg_price);
  formData.append("rent_price", rent_price);
  formData.append("security_price", security_price);
  formData.append("possession_status", possession_status);
  formData.append("brokerage", brokerage);
  formData.append("maintenance", maintenance);
  formData.append("furnishing", furnishing);
  formData.append("age_of_property", age_of_property);
  formData.append("parking", parking);


  for (const file of image) {
    formData.append("images", file, file.name);
  }
  console.log('====================================');
  console.log(formData.values());
  console.log('====================================');
  formData.get('images');
  console.log('====================================');

  for (const file of document_image) {
    formData.append("document_image", file, file.name);
  }
  try {
    const response = await fetch(`${API}property/create/`, {
      method: "POST",
      headers: {
        // 'Content-Type': 'multipart/form-data',
        authorization: `Token ${token}`,
      },
      body: formData,
    });
    return await response.json();
  } catch (err) {
    console.log(err);
    console.log("error", err);
    return err;
  }
};


import React, { useState, useEffect, useContext } from "react";
import {  useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { FaRupeeSign } from "react-icons/fa";
import { BsFillTelephoneOutboundFill } from "react-icons/bs";
import { MdLocationPin } from "react-icons/md";
import Carousel from "react-bootstrap/Carousel";
import Base from "../component/Base/Base";
import { getVehicleDetails } from "../helper/CoreAPI";
import { AuthContext } from "../AuthProvider";
import "../css/PropertyDetail.css";

const VehicleDetails = () => {
  let { id } = useParams();
  const { user } = useContext(AuthContext);

  const [VehicleInfo, setVehicleInfo] = useState({});
  const [VehicleImages, setVehicleImages] = useState([]);

  const getVehicleDetailsFunction = () => {
    getVehicleDetails(id)
      .then((res) => {
        if (res === undefined || res === null) {
          toast.error("Can't connect to server", {
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: false,
            theme: "dark",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          return setVehicleInfo([]);
        }
        if (res.detail) {
          if (res.detail === "Invalid token.") {
            setVehicleInfo([]);
            return toast.error("Invalid token. Please Login Again...", {
              position: "top-right",
              autoClose: 2500,
              hideProgressBar: false,
              theme: "dark",
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
          setVehicleInfo([]);
          return toast.error(res.detail, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            theme: "dark",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        // console.log(res)
        var image1 = [];
        image1.push(res.image);
        if (res.propertyImages?.length !== 0) {
          res.propertyImages?.map((item) => image1.push(item.image));
        }
        // console.log(image1);
        //        console.log("pass res",res.property_type.id)
        // console.log(image1);
        // res.propertyImages
        setVehicleImages(image1);
        //        console.log(res);
        return setVehicleInfo(res);
      })
      .catch((err) => {
        return toast.error(err, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          theme: "dark",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  useEffect(() => {
    return getVehicleDetailsFunction();
  }, []);

  return (
    <Base>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar
        theme="dark"
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="container my-5">
        <div className="row">
          <div className="col-lg-6">
            {VehicleImages[0] !== null && (
              <Carousel>
                {VehicleImages.map((img1, index) => (
                  <Carousel.Item key={index}>
                    <img
                      className="d-block w-100"
                      src={img1}
                      alt="First slide"
                    />
                  </Carousel.Item>
                ))}
              </Carousel>
            )}
          </div>
          <div className="col-lg-6">
            <div className="row">
              <div className="col-lg-12">
                <p id="property-detail-page-view-heading">
                  <strong>{VehicleInfo.company_name}</strong>
                </p>
              </div>
              <div className="col-lg-12">
                {user?.user?.is_staff && (
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">Owner details</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Owner Name :</td>
                        <td>{VehicleInfo.owner_name} </td>
                      </tr>
                      <tr>
                        <td>Owner Mobile No :</td>
                        <td>{VehicleInfo.owner_MobileNo} </td>
                      </tr>
                      <tr>
                        <td>Owner Email :</td>
                        <td>{VehicleInfo.owner_email} </td>
                      </tr>
                    </tbody>
                  </table>
                )}
              </div>
              <div className="col-lg-12">
                <table className="table" style={{ width: "100%" }}>
                  <thead>
                    <tr>
                      <th scope="col" colSpan={2}>
                        Property details
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Address</td>
                      <td>
                        {VehicleInfo.address_line1}
                        {/* {propertyInfo.address_line2?.length >= 1 && (
                          <>({propertyInfo.address_line2}),</>
                        )}
                        &nbsp;
                        {propertyInfo.city},&nbsp;
                        {propertyInfo.district},&nbsp;
                        {propertyInfo.state},&nbsp;
                        {propertyInfo.country}&nbsp; */}
                      </td>
                    </tr>
                    <tr>
                      <td>City :</td>
                      <td>{VehicleInfo.city} </td>
                    </tr>
                    <tr>
                      <td>District :</td>
                      <td>{VehicleInfo.district} </td>
                    </tr>
                    <tr>
                      <td> State : </td>
                      <td>{VehicleInfo.state}</td>
                    </tr>
                    <tr>
                      <td>Vehicle Type : </td>
                      <td>{VehicleInfo.vehicle_type}&nbsp;</td>
                    </tr>
                    <tr>
                      <td>Vehicle Number : </td>
                      <td>
                        {VehicleInfo?.vehicle_number !== null &&
                          VehicleInfo?.vehicle_number !== "" && (
                            <p className="flex-1" id="search-result-card-rate">
                              <span id="span-id">
                                {VehicleInfo.vehicle_number}
                              </span>
                            </p>
                          )}
                      </td>
                    </tr>
                    <tr>
                      <td>Vehicle Running : </td>
                      <td>
                        {VehicleInfo?.km_run !== null &&
                          VehicleInfo?.km_run !== "" && (
                            <p className="flex-1" id="search-result-card-rate">
                              <span id="span-id">
                                {VehicleInfo.km_run}&nbsp;KM
                              </span>
                            </p>
                          )}
                      </td>
                    </tr>
                    {VehicleInfo?.expected_price !== null &&
                      VehicleInfo?.expected_price !== "" && (
                        <tr>
                          <td>Expected Price :</td>
                          <td>
                            <span id="span-id-price">
                              <FaRupeeSign /> {VehicleInfo.expected_price}
                            </span>
                          </td>
                        </tr>
                      )}
                    {VehicleInfo.age_of_vehicle?.length >= 1 && (
                      <tr>
                        <td>Age of Vehicle :</td>
                        <td> {VehicleInfo?.age_of_vehicle}</td>
                      </tr>
                    )}
                    {VehicleInfo.geo_location?.length >= 1 && (
                      <tr>
                        <td>Geo-Coordinates :</td>
                        <td>{VehicleInfo?.geo_location}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="col-lg-12">
                {VehicleInfo.rich_text_description?.length >= 1 && (
                  <div className="mt-4">
                    <table className="table">
                      <thead>
                        <th>Description</th>
                      </thead>
                      <tbody>
                        <td>
                          <p
                            id="search-result-card-rate"
                            dangerouslySetInnerHTML={{
                              __html: VehicleInfo?.rich_text_description,
                            }}
                            className="editor"
                          ></p>
                        </td>
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* third part */}
      <div className="float" id="btn-buttons">
        {/* <Link to="/propertydetailview">
                <button className="btn" id="view-detail-btn">
                  <FaEye />
                </button>
              </Link> */}
        {VehicleInfo.google_address?.length >= 1 && (
          <a href={VehicleInfo.google_address}>
            <button className="btn my-float" id="get-location-btn">
              <MdLocationPin className="float-icon-size" />
            </button>
          </a>
        )}

        <a href={`tel:+918605399330`} className="flex-1">
          <button className="btn" id="search-contact-btn">
            <BsFillTelephoneOutboundFill className="float-icon-size" />
          </button>
        </a>
      </div>
    </Base>
  );
};

export default VehicleDetails;

import React, { useState, useEffect, useContext } from "react";
import { ToastContainer, toast } from "react-toastify";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { AuthContext } from "../../AuthProvider";
import Base from "../../component/Base/Base";
// import Homeimg from "../images/homeimg.jpg";
import { getVehicleList } from "../../helper/CoreAPI";
import VehicleResultCard from "../../component/Admin/VehicleResultCard/VehicleResultCard";
import "./Requests.css";

const VehicleRequests = () => {
  const { user } = useContext(AuthContext);
  const [vehicleList, setVehicleList] = useState([]);
  const [vehicleListVerified, setVehicleListVerified] = useState([]);
  const [vehicleListInactive, setVehicleListInactive] = useState([]);

  const getVehicleListHelper = () => {
    getVehicleList()
      .then((res) => {
        if (res === undefined || res === null) {
          toast.error("Can't connect to server", {
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: false,
            theme: "dark",
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
            draggable: true,
          });
          return setVehicleList([]);
        }
        if (res.detail) {
          if (res.detail === "Invalid token.") {
            setVehicleList([]);
            return toast.error("Invalid token. Please Login Again...", {
              position: "top-right",
              autoClose: 2500,
              hideProgressBar: false,
              theme: "dark",
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
          setVehicleList([]);
          return toast.error(res.detail, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            theme: "dark",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        console.log(res);
        var vehiclelist = [];
        var vehiclelistverified = [];
        var vehiclelistInactive = [];
        res?.forEach((element) => {
          if (element.is_verify && element.is_active) {
            vehiclelistverified.push(element);
          } else if (element.is_verify && !element.is_active) {
            vehiclelistInactive.push(element);
          } else {
            vehiclelist.push(element);
          }
        });
        setVehicleListInactive(vehiclelistInactive);
        setVehicleListVerified(vehiclelistverified);
        return setVehicleList(vehiclelist);
      })
      .catch((err) => {
        return toast.error(err, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          theme: "dark",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  useEffect(() => {
    return getVehicleListHelper();
  }, []);

  return (
    <Base>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar
        theme="dark"
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="container my-4">
        <Tabs
          defaultActiveKey="un-verified"
          id="uncontrolled-tab-example"
          className="mb-3 my-4"
        >
          <Tab eventKey="un-verified" title="Requests">
            {vehicleList?.map((item) => (
              <VehicleResultCard
                key={item.id}
                VehicleInfo={item}
                user={user}
                getVehicleListHelper={getVehicleListHelper}
              />
            ))}
          </Tab>
          <Tab eventKey="Verified" title="Verified">
            {vehicleListVerified?.map((item) => (
              <VehicleResultCard
                key={item.id}
                VehicleInfo={item}
                user={user}
                getVehicleListHelper={getVehicleListHelper}
              />
            ))}
          </Tab>
          <Tab eventKey="Inactive" title="Inactive">
            {vehicleListInactive?.map((item) => (
              <VehicleResultCard
                key={item.id}
                VehicleInfo={item}
                user={user}
                getVehicleListHelper={getVehicleListHelper}
              />
            ))}
          </Tab>
        </Tabs>
      </div>
    </Base>
  );
};

export default VehicleRequests;

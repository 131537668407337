import API from "../Backend";

export const NewVehicle = async (data, userId, token) => {
  const {
    vehicle_rent_sell,
    company_name,
    owner_name,
    owner_mobile_no,
    owner_email_id,
    vehicle_number,
    vehicle_type,
    image,
    address_line1,
    city,
    district,
    state,
    country,
    geo_location,
    google_address,
    rich_text_description,
    run_km,
    expected_price,
    age_of_vehicle,
  } = data;
  const formData = new FormData();
  formData.append("vehicle_sell_rent", vehicle_rent_sell);
  formData.append("company_name", company_name);
  formData.append("owner_name", owner_name);
  formData.append("owner_mobile_no", owner_mobile_no);
  formData.append("owner_email_id", owner_email_id);
  formData.append("user", userId);
  formData.append("vehicle_number", vehicle_number);
  formData.append("vehicle_type", vehicle_type);
  formData.append("address_line1", address_line1);
  formData.append("city", city);
  formData.append("district", district);
  formData.append("state", state);
  formData.append("country", country);
  formData.append("geo_location", geo_location);
  formData.append("google_address", google_address);
  formData.append("rich_text_description", rich_text_description);
  formData.append("km_run", run_km);
  formData.append("expected_price", expected_price);
  formData.append("age_of_vehicle", age_of_vehicle);

  for (const file of image) {
    formData.append("images", file, file.name);
  }

  try {
    const response = await fetch(`${API}vehicle/create/`, {
      method: "POST",
      headers: {
        // 'Content-Type': 'multipart/form-data',
        authorization: `Token ${token}`,
      },
      body: formData,
    });
    return await response.json();
  } catch (err) {
    console.log(err);
    console.log("error", err);
    return err;
  }
};

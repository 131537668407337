import React, { useState } from "react";
import { FaLock, FaArrowAltCircleLeft } from "react-icons/fa";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate, Link } from "react-router-dom";
import "../css/ForgetPassword.css";
import LoadingModal from "../component/LoadingModal/LoadingModal";
import {
  forgetPasswordHelper,
  forgetPasswordOtpHelper,
  forgetPasswordResetHelper,
} from "../helper/IndexAuth";

const ForgetPassword = () => {
  const navigate = useNavigate();
  const [data, setData] = useState({
    email: "",
    password: "",
    otp: "",
    otp_id: "",
    loading: false,
    email_send_success: false,
    otp_check_success: false,
    otp_resend_time: false,
    check_emailInputChange: false,
    check_passwordInputChange: false,
    check_otpInputChange: false,
    secureTextEntry: false,
    secureTextPassword: false,
  });

  const emailInputChange = (e) => {
    let reg =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (e.target.value.trim().length >= 3 && reg.test(e.target.value)) {
      setData({
        ...data,
        email: e.target.value,
        check_emailInputChange: true,
      });
    } else {
      setData({
        ...data,
        email: e.target.value,
        check_emailInputChange: false,
      });
    }
  };

  const passwordInputChange = (e) => {
    if (e.target.value.trim().length >= 3) {
      setData({
        ...data,
        password: e.target.value,
        check_passwordInputChange: true,
      });
    } else {
      setData({
        ...data,
        password: e.target.value,
        check_passwordInputChange: false,
      });
    }
  };

  const otpInputChange = (e) => {
    if (e.target.value.trim().length === 6) {
      setData({
        ...data,
        otp: e.target.value,
        check_otpInputChange: true,
      });
    } else {
      setData({
        ...data,
        otp: e.target.value,
        check_otpInputChange: false,
      });
    }
  };

  const updateSecureTextEntry = () => {
    setData({
      ...data,
      secureTextEntry: !data.secureTextEntry,
    });
  };
  const updateSecureTextEntryPassword = () => {
    setData({
      ...data,
      secureTextPassword: !data.secureTextPassword,
    });
  };

  const handleSubmit = () => {
    setData({
      ...data,
      loading: true,
    });
    forgetPasswordHelper(data.email)
      .then((res) => {
        if (res.error) {
          setData({
            ...data,
            loading: false,
          });
          return toast.error(res.error, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            theme: "dark",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        setData({
          ...data,
          loading: false,
          email_send_success: true,
        });
        // appointmentModalClose();
        return toast.success("OTP Send Successfully", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch((error) => {
        setData({
          ...data,
          loading: false,
        });
        return toast.error(error, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          theme: "dark",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  const handleSubmitOtp = () => {
    setData({
      ...data,
      loading: true,
    });
    forgetPasswordOtpHelper(data.email, data.otp)
      .then((res) => {
        if (res.error) {
          setData({
            ...data,
            loading: false,
          });
          return toast.error(res.error, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            theme: "dark",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        setData({
          ...data,
          loading: false,
          email_send_success: true,
          otp_check_success: true,
        });
        // appointmentModalClose();
        return toast.success("OTP Verify Successfully", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch((error) => {
        setData({
          ...data,
          loading: false,
        });
        return toast.error(error, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          theme: "dark",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  const handleSubmitPassword = () => {
    setData({
      ...data,
      loading: true,
    });
    forgetPasswordResetHelper(data.email, data.otp, data.password)
      .then((res) => {
        if (res.error) {
          setData({
            ...data,
            loading: false,
          });
          return toast.error(res.error, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            theme: "dark",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        setData({
          ...data,
          loading: false,
        });
        // appointmentModalClose();
        toast.success("Password changed Successfully", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return setTimeout(() => {
          return navigate("/");
        }, 2500);
      })
      .catch((error) => {
        setData({
          ...data,
          loading: false,
        });
        return toast.error(error, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          theme: "dark",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  return (
    <div
      className="container d-flex justify-content-center"
      style={{ marginTop: 70 }}
    >
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar
        theme="dark"
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {data.loading && <LoadingModal />}
      <div className="row">
        <div className="col-xl-10 col-lg-12 col-md-9">
          {data.email_send_success && !data.otp_check_success && (
            <div className="alert alert-success" role="alert">
              <p>
                We've emailed you instructions for setting your password, if an
                account exists with the email you entered. You should receive
                them shortly.
              </p>
              <p>
                If you don't receive an email, please make sure you've entered
                the address you registered with, and check your spam folder.
              </p>
            </div>
          )}
          <div className="card o-hidden border-0">
            <div className="card-body p-0">
              <div className="row">
                <div className="col-lg-12">
                  <div className="p-5">
                    <div className="text-center">
                      <h1 className="h4 text-white mb-4">
                        <FaLock color="black" size={75} />
                      </h1>
                      <h1
                        className="h4 text-dark mb-4 "
                        style={{ color: "white" }}
                      >
                        Forget Password?
                      </h1>
                    </div>
                    <form className="user">
                      <div className="form-group m-3">
                        <input
                          type="email"
                          disabled={data.email_send_success}
                          className={
                            data.check_emailInputChange
                              ? "border border-secondary form-control is-valid"
                              : "border border-secondary form-control is-invalid"
                          }
                          id="exampleInputEmail"
                          aria-describedby="emailHelp"
                          value={data.email}
                          onChange={(text) => emailInputChange(text)}
                          placeholder="Enter Email Address..."
                        />
                        {data.check_emailInputChange ? null : (
                          <div
                            id="validationServerNameFeedback"
                            className="invalid-feedback text-white"
                          >
                            Please enter a valid email address.
                          </div>
                        )}
                      </div>
                      {data.email_send_success && (
                        <div className="form-group flex m-3">
                          <input
                            type={data.secureTextEntry ? "text" : "password"}
                            className={
                              data.check_otpInputChange
                                ? "border border-secondary form-control is-valid"
                                : "border border-secondary form-control is-invalid"
                            }
                            id="exampleInputOtp"
                            value={data.otp}
                            onChange={(text) => otpInputChange(text)}
                            placeholder="OTP"
                            disabled={data.otp_check_success}
                          />
                          <button
                            type="button"
                            className="btn btn-primary btn-sm"
                            onClick={updateSecureTextEntry}
                          >
                            {data.secureTextEntry ? (
                              <AiFillEye size={25} />
                            ) : (
                              <AiFillEyeInvisible size={25} />
                            )}
                          </button>
                        </div>
                      )}
                      {data.email_send_success && data.otp_check_success && (
                        <div className="form-group flex m-3">
                          <input
                            type={data.secureTextPassword ? "text" : "password"}
                            className={
                              data.check_passwordInputChange
                                ? "border border-secondary form-control is-valid"
                                : "border border-secondary form-control is-invalid"
                            }
                            id="exampleInputPassword"
                            value={data.password}
                            onChange={(text) => passwordInputChange(text)}
                            placeholder="Password"
                          />
                          <button
                            type="button"
                            className="btn btn-primary btn-sm"
                            onClick={updateSecureTextEntryPassword}
                          >
                            {data.secureTextPassword ? (
                              <AiFillEye size={25} />
                            ) : (
                              <AiFillEyeInvisible size={25} />
                            )}
                          </button>
                        </div>
                      )}

                      {!data.email_send_success && (
                        <button
                          style={{
                            opacity: 1,
                            display: "block",
                            margin: "auto",
                          }}
                          type="button"
                          onClick={() => handleSubmit()}
                          className="btn btn-dark"
                          disabled={!data.check_emailInputChange}
                        >
                          SUBMIT
                        </button>
                      )}
                      {data.email_send_success && !data.otp_check_success && (
                        <button
                          style={{
                            opacity: 1,
                            display: "block",
                            margin: "auto",
                          }}
                          type="button"
                          onClick={() => handleSubmitOtp()}
                          className="btn btn-dark"
                          disabled={!data.check_emailInputChange}
                        >
                          SUBMIT OTP
                        </button>
                      )}
                      {data.email_send_success && data.otp_check_success && (
                        <button
                          style={{
                            opacity: 1,
                            display: "block",
                            margin: "auto",
                          }}
                          type="button"
                          onClick={() => handleSubmitPassword()}
                          className="btn btn-dark"
                          disabled={!data.check_emailInputChange}
                        >
                          SUBMIT Password
                        </button>
                      )}
                    </form>
                    <Link to="/">
                      <FaArrowAltCircleLeft size={30} />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;

import API from '../Backend';

export const signin = async (email, password) => {
  const formData = new FormData();
  formData.append('email', email);
  formData.append('password', password);

  const response = await fetch(`${API}user/login/`, {
    method: 'POST',
    body: formData,
  });
  return response.json();
};

export const signup = async (user) => {
  const { first_name, last_name, email, password, mobileno } = user;

  const formData = new FormData();
  formData.append('first_name', first_name);
  formData.append('last_name', last_name);
  formData.append('email', email);
  formData.append('password', password);
  formData.append('mobile_no', mobileno);


  const response = await fetch(`${API}user/register/`, {
    method: 'POST',
    body: formData,
  });
  return response.json();
};

export const authenticate = (data) => {
  if (typeof window !== undefined) {
    localStorage.setItem('authenticationData', JSON.stringify(data));
  }
};

export const isAuthenticated = () => {
  if (typeof window == undefined) {
    return false;
  }
  if (localStorage.getItem('authenticationData')) {
    return JSON.parse(localStorage.getItem('authenticationData'));
  }
  return false;
};

export const signout = () => {
  // const userId = isAuthenticated() && isAuthenticated().user.id;

  if (typeof window !== undefined) {
    localStorage.removeItem('authenticationData');

    return fetch(`${API}user/logout/`, {
      method: 'GET',
    })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err;
      });
  }
};

export const deleteUserHelper = async (token,id) => {
  try {
    const response = await fetch(`${API}user/register/${id}/`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Token ${token}`,
      },
    });
    return await response.json();
  } catch (err) {
    return err;
  }
};


export const forgetPasswordHelper = async(email) => {
  const formData = new FormData();
  formData.append('email', email);

  const response = await fetch(`${API}user/forget-password/`, {
    method: 'POST',
    body: formData,
  });
  return response.json();
}

export const forgetPasswordOtpHelper = async(email,otp) => {
  const formData = new FormData();
  formData.append('email', email);
  formData.append('otp', otp);

  const response = await fetch(`${API}user/forget-password/verify/`, {
    method: 'POST',
    body: formData,
  });
  return response.json();
}

export const forgetPasswordResetHelper = async(email,otp,password) => {
  const formData = new FormData();
  formData.append('email', email);
  formData.append('otp', otp);
  formData.append('password', password);
  const response = await fetch(`${API}user/forget-password/reset/`, {
    method: 'POST',
    body: formData,
  });
  return response.json();  
}




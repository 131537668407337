import React from "react";
import "./Sidebars.css";
import NavigationBar from "../NavigationBar/NavigationBar";
import Footer from "../Footer/Footer";

const Base = ({ children }) => {
  return (
    <div>
      {/* navigationbar starts */}
      <NavigationBar />
      
      {/* navigationbar ends */}

      {children}

      {/* Footer starts  */}
      <Footer />
      {/* Footer ends  */}
    </div>
  );
};

export default Base;

import React from "react";
import { MdEmail } from "react-icons/md";
import { AiOutlineInstagram } from "react-icons/ai";
import { RiFacebookFill, RiWhatsappFill } from "react-icons/ri";
import { BsTelephoneFill } from "react-icons/bs";
import "./Footer.css";

const Footer = () => {
  return (
    <div className="footer">
      <div className="flex">
        <div className="social-media-links flex-1">
          <p className="flex-1">Join us at </p>
          <div className="social-media">
            <div className="social-media flex-1">
              <a
                className="social-media-icon"
                href="https://www.instagram.com/poconest.in/?utm_medium=copy_link"
              >
                <AiOutlineInstagram id="social-media-icon" size={45} />
              </a>
            </div>
            <div className="social-media flex-1">
              <span className="social-media-icon">
                <RiFacebookFill id="social-media-icon" size={45} />
              </span>
            </div>
            <div className="social-media flex-1">
              <a className="social-media-icon" href="https://wa.me/9359402788">
                <RiWhatsappFill id="social-media-icon" size={45} />
              </a>
            </div>
          </div>
        </div>
        <div className="our-services flex-1">
          <p className="flex-1">we operate here </p>
          <ul>
            <li>Tumsar</li>
            <li>Mohadi</li>
            <li>Sihora</li>
            <li>Bhandara</li>
            <li>Devada</li>
            <li>Sukdi</li>
            <li>and many more...</li>
          </ul>
        </div>
        <div className="contact-section flex-1">
          <p className="flex-1">contact us </p>
          <div className="contact">
            <div className="contact-center">
              <span className="social-media-icon flex-1">
                <BsTelephoneFill id="social-media-icon" size={45} />
              </span>
              <span className="social-media-icon flex-1">8605399330</span>
            </div>
            <br />
            <div className="contact-center">
              <a
                className="social-media-icon flex-1"
                href="mailto:support@poconest.in"
              >
                <MdEmail id="social-media-icon" size={45} />
              </a>
              <span className="social-media-icon flex-1">
                support@poconest.in
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { ToastContainer, toast } from "react-toastify";
import { RiHomeSmileFill } from "react-icons/ri";
import { BsCloudUploadFill } from "react-icons/bs";
import { MdOutlineDirectionsBike } from "react-icons/md";
import Base from "../component/Base/Base";
import { getPropertyTypeList } from "../helper/PropertyType";
import "../css/Home.css";
import { getPropertyList, getVehicleListFilter } from "../helper/CoreAPI";
import SearchResultCard from "../component/SearchResultCard/SearchResultCard";
import VehicleResultCard from "../component/VehicleResultCard/VehicleResultCard";

// import { FloatingButton } from "react-floating-button";

const Home = () => {
  const [propertyTypeList, setPropertyTypeList] = useState([]);
  const [propertySellList, setPropertySellList] = useState([]);
  const [VehicleSellList, setVehicleSellList] = useState([]);
  const [propertyRentList, setPropertyRentList] = useState([]);
  const [data, setData] = useState({
    select_property_type: "selected",
    select_city: "selected",
    properrty_sell_rent: "selected",
    check_propertyTypeInputChange: false,
    check_properrty_sell_rentInputChange: false,
    check_cityInputChange: false,
  });

  const handleChangeInputWithValidation = (key, validationKey, e) => {
    if (e.target.value.trim().length >= 1) {
      setData({
        ...data,
        [key]: e.target.value,
        [validationKey]: true,
      });
    } else {
      setData({
        ...data,
        [key]: e.target.value,
        [validationKey]: false,
      });
    }
  };

  const getPropertyTypeListHelper = () => {
    getPropertyTypeList()
      .then((res) => {
        if (res === undefined || res === null) {
          toast.error("Can't connect to server", {
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: false,
            theme: "dark",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          return setPropertyTypeList([]);
        }
        if (res.detail) {
          if (res.detail === "Invalid token.") {
            setPropertyTypeList([]);
            return toast.error("Invalid token. Please Login Again...", {
              position: "top-right",
              autoClose: 2500,
              hideProgressBar: false,
              theme: "dark",
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
          setPropertyTypeList([]);
          return toast.error(res.detail, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            theme: "dark",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        // console.log(res)
        return setPropertyTypeList(res);
      })
      .catch((err) => {
        return toast.error(err, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          theme: "dark",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  const getPropertyListHelper = () => {
    getPropertyList()
      .then((res) => {
        if (res === undefined || res === null) {
          toast.error("Can't connect to server", {
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: false,
            theme: "dark",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setPropertySellList([]);
          return setPropertyRentList([]);
        }
        if (res.detail) {
          if (res.detail === "Invalid token.") {
            setPropertySellList([]);
            setPropertyRentList([]);
            return toast.error("Invalid token. Please Login Again...", {
              position: "top-right",
              autoClose: 2500,
              hideProgressBar: false,
              theme: "dark",
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
          setPropertySellList([]);
          setPropertyRentList([]);
          return toast.error(res.detail, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            theme: "dark",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        var res_rent = res.filter(
          (s, index) => s.properrty_sell_rent === "Rent"
        );
        var res_sell = res.filter(
          (s, index) => s.properrty_sell_rent === "Sell"
        );

        res_rent = res_rent.slice(0, 3);
        res_sell = res_sell.slice(0, 3);

        // if (res_rent.length > 5) {
        //   var res1 = [];
        //   for (let index = 0; index < 5; index++) {
        //     const random = Math.floor(Math.random() * res.length);
        //     res1.push(res[random]);
        //   }
        // setPropertyList(res1);
        // }
        setPropertySellList(res_sell);
        setPropertyRentList(res_rent);
        //        console.log(res)
        return res;
      })
      .catch((err) => {
        return toast.error(err, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          theme: "dark",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  const getVehicleListHelper = () => {
    getVehicleListFilter()
      .then((res) => {
        if (res === undefined || res === null) {
          toast.error("Can't connect to server", {
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: false,
            theme: "dark",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          return setVehicleSellList([]);
        }
        if (res.detail) {
          if (res.detail === "Invalid token.") {
            setVehicleSellList([]);
            return toast.error("Invalid token. Please Login Again...", {
              position: "top-right",
              autoClose: 2500,
              hideProgressBar: false,
              theme: "dark",
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
          setVehicleSellList([]);
          return toast.error(res.detail, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            theme: "dark",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }

        // if (res_rent.length > 5) {
        //   var res1 = [];
        //   for (let index = 0; index < 5; index++) {
        //     const random = Math.floor(Math.random() * res.length);
        //     res1.push(res[random]);
        //   }
        // setPropertyList(res1);
        // }
        //        console.log(res)
        return setVehicleSellList(res);
      })
      .catch((err) => {
        return toast.error(err, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          theme: "dark",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  useEffect(() => {
    // return () => {
    getVehicleListHelper();
    getPropertyListHelper();
    getPropertyTypeListHelper();
    // }
  }, []);

  return (
    <Base>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar
        theme="dark"
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <div className="back-img">
        <img className="home-img" alt="..." src={"/images/u0ksh4zkpj.jpeg"} />
      </div>

      {/* search part starts */}
      <div className="search-container">
        <form action="/searchresults" method="GET">
          <div className="search-container-box">
            <div className="search-container-box-select flex-1">
              <select
                id="properrty_sell_rent"
                name="properrty_sell_rent"
                className={
                  data.check_properrty_sell_rentInputChange
                    ? "form-control is-valid"
                    : "form-control is-invalid"
                }
                value={data.properrty_sell_rent}
                onChange={(text) =>
                  handleChangeInputWithValidation(
                    "properrty_sell_rent",
                    "check_properrty_sell_rentInputChange",
                    text
                  )
                }
              >
                <option disabled value="selected">
                  Select Buy/Rent
                </option>
                <option value="Sell">Buy</option>
                <option value="Rent">Rent</option>
              </select>
            </div>
            <div className="search-container-box-select flex-1">
              <select
                id="property_type"
                name="property_type"
                className={
                  data.check_propertyTypeInputChange
                    ? "form-control is-valid"
                    : "form-control is-invalid"
                }
                value={data.select_property_type}
                onChange={(text) =>
                  handleChangeInputWithValidation(
                    "select_property_type",
                    "check_propertyTypeInputChange",
                    text
                  )
                }
              >
                <option disabled value="selected">
                  Select Property type
                </option>
                {propertyTypeList?.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.sub_type}
                  </option>
                ))}
              </select>
            </div>
            <div className="search-container-box-select flex-1">
              <select
                id="city"
                name="city"
                className={
                  data.check_cityInputChange
                    ? "form-control is-valid"
                    : "form-control is-invalid"
                }
                value={data.select_city}
                onChange={(text) =>
                  handleChangeInputWithValidation(
                    "select_city",
                    "check_cityInputChange",
                    text
                  )
                }
              >
                <option disabled value="selected">
                  Select City
                </option>
                <option value="Tumsar">Tumsar</option>
                <option value="Mohadi">Mohadi</option>
                <option value="Sihora">Sihora</option>
                <option value="Bhandara">Bhandara</option>
                <option value="Devada">Devada</option>
                <option value="Sukdi">Sukdi</option>
                <option value="other">other</option>
              </select>
            </div>
            <button
              type="submit"
              disabled={
                !data.check_properrty_sell_rentInputChange ||
                !data.check_cityInputChange ||
                !data.check_propertyTypeInputChange
              }
              id="search-btn"
            >
              Search
            </button>
          </div>
        </form>
      </div>
      {/* search part ends  */}

      <div className="box-container">
        <div className="row">
          <div className="col-sm-12 col-md-6 col-lg-6 mt-2">
            <Link to="/upload/property">
              <button className="upload-home-button">
                <BsCloudUploadFill size={30} style={{ marginRight: "10px" }} />{" "}
                Upload property for FREE
                <RiHomeSmileFill
                  size={30}
                  style={{ marginLeft: "10px" }}
                />{" "}
              </button>
            </Link>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6 mt-2">
            <Link to="/upload/vehicle">
              <button className="upload-home-button">
                <BsCloudUploadFill size={30} style={{ marginRight: "10px" }} />{" "}
                Upload vehicles for FREE
                <MdOutlineDirectionsBike
                  size={30}
                  style={{ marginLeft: "10px" }}
                />{" "}
              </button>
            </Link>
          </div>
        </div>

        <Tabs defaultActiveKey="buy" id="hometabs" className="mb-3">
          <Tab eventKey="buy" title="Buy">
            {/* <h3>Buy Property</h3> */}
            <div className="boxes flex-1">
              <div className="flex-for-pc flex-for-mob flex-1-for-pc">
                <Link
                  to="/searchresults?properrty_sell_rent=Sell&property_type=4"
                  className="box box-1 flex-1"
                  style={{ textDecoration: "none" }}
                >
                  <span>Houses</span>
                </Link>
                <Link
                  to="/searchresults?properrty_sell_rent=Sell&property_type=1"
                  className="box box-2 flex-1"
                  style={{ textDecoration: "none" }}
                >
                  <span>Building</span>
                </Link>
              </div>
              <div className="flex-for-mob flex-for-pc flex-1-for-pc">
                <Link
                  to="/searchresults?properrty_sell_rent=Sell&property_type=3"
                  className="box box-3 flex-1"
                  style={{ textDecoration: "none" }}
                >
                  <span>Flats</span>
                </Link>
                <Link
                  to="/searchresults?properrty_sell_rent=Sell&property_type=5"
                  className="box box-4 flex-1"
                  style={{ textDecoration: "none" }}
                >
                  <span>Farm</span>
                </Link>
              </div>
            </div>
          </Tab>
          <Tab eventKey="rent" title="Rent">
            {/* <h3>Rent Property</h3> */}
            <div className="boxes flex-1">
              <div className="flex-for-pc flex-for-mob flex-1-for-pc">
                <Link
                  to="/searchresults?properrty_sell_rent=Rent&property_type=4"
                  className="box box-1 flex-1"
                  style={{ textDecoration: "none" }}
                >
                  <span>Houses</span>
                </Link>
                <Link
                  to="/searchresults?properrty_sell_rent=Rent&property_type=1"
                  className="box box-2 flex-1"
                  style={{ textDecoration: "none" }}
                >
                  <span>Building</span>
                </Link>
              </div>
              <div className="flex-for-mob flex-for-pc flex-1-for-pc">
                <Link
                  to="/searchresults?properrty_sell_rent=Rent&property_type=3"
                  className="box box-3 flex-1"
                  style={{ textDecoration: "none" }}
                >
                  <span>Flats</span>
                </Link>
                <Link
                  to="/searchresults?properrty_sell_rent=Rent&property_type=5"
                  className="box box-4 flex-1"
                  style={{ textDecoration: "none" }}
                >
                  <span>Farm</span>
                </Link>
              </div>
            </div>
          </Tab>
        </Tabs>
      </div>

      <div className="box-container-2">
        <h3>Property for Sell</h3>
        <div className="container">
          {propertySellList?.map((item) => (
            <SearchResultCard key={item.id} propertyInfo={item} />
          ))}
        </div>
        <div class="d-flex justify-content-end mx-4 my-2">
          <Link className="btn btn-info" to="/property">
            View More
          </Link>
        </div>
      </div>
      {propertyRentList.length > 0 && (
        <div className="box-container-2">
          <h3>Property for Rent</h3>
          <div className="container">
            {propertyRentList?.map((item) => (
              <SearchResultCard key={item.id} propertyInfo={item} />
            ))}
          </div>
        </div>
      )}
      {VehicleSellList.length > 0 && (
        <div className="box-container-2">
          <h3>Vehicle for Sell</h3>
          <div className="container">
            {VehicleSellList?.map((item) => (
              <VehicleResultCard key={item.id} vehicleInfo={item} />
            ))}
          </div>
        </div>
      )}

      {/* properties-part starts  */}
      <div style={{ marginTop: "5px" }}></div>
      {/* properties-part ends  */}
    </Base>
  );
};

export default Home;

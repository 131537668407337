import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import CloseButton from "react-bootstrap/CloseButton";
import Carousel from "react-bootstrap/Carousel";
import { toast } from "react-toastify";
import { FaRupeeSign } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { PropertyVerifyActiveHelpler } from "../../../helper/AdminHelper";
import "./ResultCard.css";
import {
  deletePropertyHelper,
  getDummyImageListHelper,
} from "../../../helper/CoreAPI";

const ResultCard = ({ propertyInfo, user, getPropertyListHelper }) => {
  const [modalShow, setModalShow] = useState(false);
  const [modalData, setModalData] = useState({
    verify: false,
    active: false,
    delete: false,
  });
  const [dummyImage, setDummyImage] = useState({});

  // console.log("length pass image",propertyInfo?.image?.length)

  const getDummyImageList = () => {
    getDummyImageListHelper()
      .then((res) => {
        if (res === undefined || res === null) {
          toast.error("Can't Find Dummy Image List", {
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: false,
            theme: "dark",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          return setDummyImage({});
        }
        // console.log("res pass", res[propertyInfo.property_type.sub_type]);
        return setDummyImage(res);
      })
      .catch((err) => {
        return toast.error(err, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          theme: "dark",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  const modalDataChange = (key, value) => {
    setModalData({
      ...modalData,
      [key]: value,
    });
  };

  const PropertyVerifyActive = (is_verify, is_active) => {
    PropertyVerifyActiveHelpler(
      propertyInfo.id,
      is_verify,
      is_active,
      user.token
    )
      .then((res) => {
        if (res === undefined || res === null) {
          toast.error("Can't connect to server", {
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: false,
            theme: "dark",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        if (res.detail) {
          if (res.detail === "Invalid token.") {
            return toast.error("Invalid token. Please Login Again...", {
              position: "top-right",
              autoClose: 2500,
              hideProgressBar: false,
              theme: "dark",
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
          return toast.error(res.detail, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            theme: "dark",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        toast.success("Your Property details successfully Updated.", {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        getPropertyListHelper();
        return setModalData({
          verify: false,
          active: false,
          delete: false,
        });
      })
      .catch((error) => {
        return toast.error(error, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          theme: "dark",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  const deleteProperty = () => {
    deletePropertyHelper(propertyInfo.id, user.token)
      .then((res) => {
        // console.log("res delete",res)
        // console.log("res delete",typeof(res))

        if (res === undefined || res === null) {
          toast.error("Can't connect to server", {
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: false,
            theme: "dark",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        if (res.detail) {
          if (res.detail === "Invalid token.") {
            return toast.error("Invalid token. Please Login Again...", {
              position: "top-right",
              autoClose: 2500,
              hideProgressBar: false,
              theme: "dark",
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
          return toast.error(res.detail, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            theme: "dark",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        toast.success("Property successfully Deleted.", {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        getPropertyListHelper();
        return setModalData({
          verify: false,
          active: false,
          delete: false,
        });
      })
      .catch((error) => {
        return toast.error(error, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          theme: "dark",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  useEffect(() => {
    return getDummyImageList();
  }, []);

  // console.log(propertyInfo);
  return (
    <>
      <Modal show={modalData.verify} centered>
        <Modal.Header closeButton>
          <Modal.Title>Property Verify Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to Confirm!.</p>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => modalDataChange("verify", false)}
          >
            Close
          </Button>
          <Button
            variant="info"
            onClick={() => PropertyVerifyActive(propertyInfo.is_verify, null)}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={modalData.active} centered>
        <Modal.Header closeButton>
          <Modal.Title>Property Active Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to Confirm!.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => modalDataChange("active", false)}
          >
            Close
          </Button>
          <Button
            variant="info"
            onClick={() => PropertyVerifyActive(null, propertyInfo.is_active)}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={modalData.delete} centered>
        <Modal.Header closeButton>
          <Modal.Title>Property Delete Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to Delete!.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => modalDataChange("delete", false)}
          >
            Close
          </Button>
          <Button variant="info" onClick={() => deleteProperty()}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={modalShow}
        size="lg"
        backdrop="static"
        fullscreen
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header style={{ backgroundColor: "#FF6263" }}>
          <Modal.Title id="contained-modal-title-vcenter">
            {propertyInfo.name}
          </Modal.Title>
          <CloseButton onClick={() => setModalShow(false)} />
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col col-lg-6">
              {propertyInfo?.image?.length !== 0 &&
                propertyInfo?.image?.length !== undefined && (
                  <Carousel>
                    <Carousel.Item>
                      <img
                        className="d-block w-100"
                        src={propertyInfo?.image}
                        alt="First slide"
                      />
                    </Carousel.Item>
                    {propertyInfo?.propertyImages?.map((img1, index) => (
                      <Carousel.Item key={index}>
                        <img
                          className="d-block w-100"
                          src={img1.image}
                          alt="First slide"
                        />
                      </Carousel.Item>
                    ))}
                  </Carousel>
                )}
              {(propertyInfo?.image?.length === 0 ||
                propertyInfo?.image?.length === undefined) && (
                <Carousel>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src={dummyImage[propertyInfo.property_type?.sub_type]}
                      alt="First slide"
                    />
                    {/* <Carousel.Caption>
                      <h3>First slide label</h3>
                      <p>
                        Nulla vitae elit libero, a pharetra augue mollis
                        interdum.
                      </p>
                    </Carousel.Caption> */}
                  </Carousel.Item>
                </Carousel>
              )}
            </div>
            <div className="col col-lg-6">
              <div className="Row">
                <p className="border border-dark border-top-0 border-end-0 border-start-0 fw-bold">
                  {propertyInfo.name}
                </p>
              </div>
              <div className="Row">
                {user?.user?.is_staff && (
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Owner details</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Owner Name :</td>
                        <td>{propertyInfo.owner_name} </td>
                      </tr>
                      <tr>
                        <td>Owner Mobile No :</td>
                        <td>{propertyInfo.owner_MobileNo} </td>
                      </tr>
                      <tr>
                        <td>Owner Email :</td>
                        <td>{propertyInfo.owner_email} </td>
                      </tr>
                    </tbody>
                  </table>
                )}
                <table className="table">
                  <thead>
                    <tr>
                      <th colSpan={2}>Property details</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Address</td>
                      <td>
                        {propertyInfo.address_no?.length >= 1 &&
                          propertyInfo.address_no}
                        ,&nbsp;
                        {propertyInfo.floor_number?.length >= 1 && (
                          <>{propertyInfo.floor_number},</>
                        )}
                        &nbsp;
                        {propertyInfo.address_line1},&nbsp;
                        {propertyInfo.address_line2?.length >= 1 && (
                          <>({propertyInfo.address_line2}),</>
                        )}
                        &nbsp;
                        {propertyInfo.city},&nbsp;
                        {propertyInfo.district},&nbsp;
                        {propertyInfo.state},&nbsp;
                        {propertyInfo.country}&nbsp;
                      </td>
                    </tr>
                    <tr>
                      <td>City :</td>
                      <td>{propertyInfo.city} </td>
                    </tr>
                    <tr>
                      <td>District :</td>
                      <td>{propertyInfo.district} </td>
                    </tr>
                    <tr>
                      <td> State : </td>
                      <td>{propertyInfo.state}</td>
                    </tr>
                    <tr>
                      <td>Area : </td>
                      <td>
                        {propertyInfo.size}&nbsp;&nbsp;
                        <strong>Sq.ft</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>Per sq.ft rate : </td>
                      <td>
                        {propertyInfo?.avg_price !== null &&
                          propertyInfo?.avg_price !== "" && (
                            <p className="flex-1" id="search-result-card-rate">
                              Per sq.ft rate :{" "}
                              <span id="span-id">{propertyInfo.avg_price}</span>
                              &nbsp;
                              <strong>Per sq ft</strong>
                            </p>
                          )}
                      </td>
                    </tr>
                    {propertyInfo?.rate_price !== null &&
                      propertyInfo?.rate_price !== "" && (
                        <tr>
                          <td>Price :</td>
                          <td>
                            <span id="span-id-price">
                              <FaRupeeSign /> {propertyInfo.rate_price}
                            </span>
                          </td>
                        </tr>
                      )}
                    {propertyInfo?.rent_price !== null &&
                      propertyInfo?.rent_price !== "" && (
                        <tr>
                          <td> Rent Price</td>
                          <td>
                            {" "}
                            <span id="span-id-price">
                              {" "}
                              <FaRupeeSign /> {propertyInfo.rent_price}
                            </span>
                          </td>
                        </tr>
                      )}
                    {propertyInfo?.security_price !== null &&
                      propertyInfo?.security_price !== "" && (
                        <tr>
                          <td>Security Price :</td>
                          <td>
                            {" "}
                            <span id="span-id">
                              {" "}
                              <FaRupeeSign /> {propertyInfo.security_price}{" "}
                            </span>
                          </td>
                        </tr>
                      )}
                    {propertyInfo.age_of_property?.length >= 1 && (
                      <tr>
                        <td>Age of Property :</td>
                        <td> {propertyInfo?.age_of_property}</td>
                      </tr>
                    )}
                    {propertyInfo.geo_location?.length >= 1 && (
                      <tr>
                        <td>Geo-Coordinates :</td>
                        <td>{propertyInfo?.geo_location}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="col">
                {propertyInfo.rich_text_description?.length >= 1 && (
                  <div class="mt-4">
                    <table class="table">
                      <thead>
                        <th>Description</th>
                      </thead>
                      <tbody>
                        <td>
                          <p
                            id="search-result-card-rate"
                            dangerouslySetInnerHTML={{
                              __html: propertyInfo?.rich_text_description,
                            }}
                            className="editor"
                          ></p>
                        </td>
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer
          style={{ backgroundColor: "#242B2E", height: "50px", padding: 0 }}
        >
          {propertyInfo.is_verify ? (
            <Button
              className="border-0"
              style={{ backgroundColor: "#38CC77" }}
              disabled
            >
              Verified
            </Button>
          ) : (
            <Button
              onClick={() => modalDataChange("verify", true)}
              className="border-0"
              style={{ backgroundColor: "#38CC77" }}
            >
              Verify
            </Button>
          )}
          {propertyInfo.is_active ? (
            <Button
              onClick={() => modalDataChange("active", true)}
              className="border-0"
              style={{ backgroundColor: "#207398" }}
            >
              Deactivate
            </Button>
          ) : (
            <Button
              onClick={() => modalDataChange("active", true)}
              className="border-0"
              style={{ backgroundColor: "#207398" }}
            >
              Activate
            </Button>
          )}
          delete
          <Button
            variant="danger"
            onClick={() => modalDataChange("delete", true)}
          >
            <MdDelete size={25} />
          </Button>
        </Modal.Footer>
      </Modal>
      <div class="card mb-3 my-4" style={{ width: "100%" }}>
        <div class="row g-0">
          <div class="col-md-4">
            {propertyInfo?.image !== null && (
              <img
                height={250}
                width="100%"
                src={propertyInfo.image}
                class="img-fluid rounded-start"
                alt="..."
              />
            )}
            {propertyInfo?.image === null && (
              <img
                height={250}
                width="100%"
                style={{ objectFit: "cover" }}
                src={dummyImage[propertyInfo.property_type.sub_type]}
                alt="..."
              />
            )}
            <div class="position-absolute top-0 start-0 m-3 badges-bag">
              <span class="top-3 start-0 translate-middle badge rounded-pill bg-* badge-image-count">
                {propertyInfo.propertyImages?.length + 1}
              </span>

              {propertyInfo.properrty_sell_rent === "Sell" ? (
                <span class="top-3 start-1 translate-middle badge rounded-pill bg-* badge-sell">
                  {propertyInfo.properrty_sell_rent}
                </span>
              ) : (
                <span class=" top-3 start-1 translate-middle badge rounded-pill bg-* badge-rent">
                  {propertyInfo.properrty_sell_rent}
                </span>
              )}
              {propertyInfo?.image === null && (
                <span className="top-3 start-0 translate-middle badge rounded-pill bg-light badge-dummy-image">
                  Dummy Image
                </span>
              )}
            </div>
          </div>
          <div class="col-md-8">
            <div class="card-body">
              <h5 class="card-title">{propertyInfo.name}</h5>
              <p class="card-text">
                {propertyInfo.address_no?.length >= 1 &&
                  propertyInfo.address_no}
                ,&nbsp;
                {propertyInfo.floor_number?.length >= 1 && (
                  <>{propertyInfo.floor_number},</>
                )}
                &nbsp;
                {propertyInfo.address_line1.substring(0, 40)}...,&nbsp;
              </p>
              <div className="row">
                <div className="col-lg-4">
                  <p className="card-text">
                    City:&nbsp;<strong>{propertyInfo.city}</strong>
                  </p>
                </div>
                <div className="col-lg-4">
                  <p className="card-text">
                    District:&nbsp;<strong>{propertyInfo.district}</strong>
                  </p>
                </div>
                <div className="col-lg-4">
                  <p className="card-text">
                    Area:&nbsp;<strong>{propertyInfo.size}&nbsp;sq ft</strong>
                  </p>
                </div>
              </div>
              <div className="row">
                {propertyInfo?.avg_price !== null &&
                  propertyInfo?.avg_price !== "" && (
                    <div className="col-lg-4">
                      <p className="card-text">
                        Rate:&nbsp;
                        <strong>{propertyInfo.avg_price}&nbsp;per sq ft</strong>
                      </p>
                    </div>
                  )}
                {propertyInfo?.rate_price !== null &&
                  propertyInfo?.rate_price !== "" && (
                    <div className="col-lg-4">
                      <p className="card-text">
                        Price:&nbsp;
                        <span className="span-price">
                          <strong>
                            <FaRupeeSign />
                            {propertyInfo.rate_price}
                          </strong>
                        </span>
                      </p>
                    </div>
                  )}
                {propertyInfo?.rent_price !== null &&
                  propertyInfo?.rent_price !== "" && (
                    <div className="col-lg-4">
                      <p className="card-text">
                        Rent Price:&nbsp;
                        <span className="span-price">
                          <strong>
                            {" "}
                            <FaRupeeSign />
                            {propertyInfo.rent_price}&nbsp;
                          </strong>
                        </span>
                      </p>
                    </div>
                  )}
                {propertyInfo?.security_price !== null &&
                  propertyInfo?.security_price !== "" && (
                    <div className="col-lg-4">
                      <p className="card-text">
                        Security Price:&nbsp;
                        <strong>
                          {propertyInfo.security_price}&nbsp;per sq ft
                        </strong>
                      </p>
                    </div>
                  )}
              </div>
              <div className="row my-4">
                <div className="col-lg-4">
                  <button
                    type="button"
                    onClick={() => setModalShow(true)}
                    className="btn btn-info"
                  >
                    View Details
                  </button>
                </div>
                {/* <div className="col-lg-4">
                  <button type="button" className="btn btn-primary">
                    Is Verified
                  </button>
                </div>
                <div className="col-lg-4">
                  <button type="button" className="btn btn-success">
                    Is Active
                  </button>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResultCard;

import React, { useState, useRef, useEffect, useContext } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Button from "react-bootstrap/Button";
import { ToastContainer, toast } from "react-toastify";
import { BiShow } from "react-icons/bi";
import { MdDelete } from "react-icons/md";
import { AuthContext } from "../AuthProvider";
import editorConfiguration from "../helper/editorConfiguration";
import { getStateListHelper } from "../helper/CoreAPI";
import Base from "../component/Base/Base";
import OwnerInformation from "../component/PropertyUploadForm/OwnerInformation";
import ImageModalView from "../component/PropertyUploadForm/ImageModalView";
import OwnerModalVerify from "../component/PropertyUploadForm/OwnerModalVerify";
import { NewVehicle } from "../helper/VehicleHelper";
import "../css/PropertyUploadForm.css";


const UploadVehicle = () => {
  const { user } = useContext(AuthContext);
  const [stateList, setStateList] = useState({});
  const [data, setData] = useState({
    vehicle_rent_sell: "Sell",
    company_name: "",
    owner_name: "",
    owner_mobile_no: "",
    owner_email_id: "",
    vehicle_number: "",
    vehicle_type: "selected",
    image: [],
    address_line1: "",
    city: "Tumsar",
    district: "Bhandara",
    state: "Maharashtra",
    country: "India",
    geo_location1: {
      lat: 0,
      lng: 0,
    },
    geo_location: "",
    google_address: "",
    rich_text_description: "",
    run_km: "",
    expected_price: "",
    rent_price: "",
    brokerage: "",
    maintenance: "",
    age_of_vehicle: "",
    otp: "",
    email_send_otp_loading: false,
    email_send_success_otp: false,
    verify_otp: false,
    check_otpInputChange: false,
    check_nameInputChange: false,
    check_vehicleNoInputChange: false,
    check_cityInputChange: true,
    check_ownerInputName: false,
    check_ownerInputMobileno: false,
    check_ownerMobilenoVerified: false,
    check_ownerInputEmail: false,
    check_vehicleTypeInputChange: false,
    check_addressLine1InputChange: false,
  });

  const [modalImageShow, setModalShow] = useState(false);
  const [modalVerifyShow, setModalVerifyShow] = useState(false);
  const [modalImageLink, setModalImageLink] = useState("");

  const handleVerifyModalClose = () => setModalVerifyShow(false);
  const handlVerifyModaleShow = () => setModalVerifyShow(true);

  const handleModalImageClose = () => {
    setModalImageLink("");
    setModalShow(false);
  };
  // console.log(propertyTypeList);

  const handleModalImageShow = (image) => {
    setModalImageLink(image);
    setModalShow(true);
  };

  const hiddenFileInput = useRef(null);

  const handleClickImage = (event) => {
    hiddenFileInput.current.click();
  };
  const handleChangeImage = (event) => {
    const fileUploaded = Object.values(event.target.files);
    var images_array = data.image;
    // console.log("values",Object.values(data.image))
    // console.log("files upload",Object.values(fileUploaded))
    // console.log("length array",images_array.length)
    for (const file1 of fileUploaded) {
      var file2 = file1;
      file2["path"] = URL.createObjectURL(file1);
      images_array.push(file2);
    }
    setData({
      ...data,
      image: images_array,
    });
  };

  const handleDeleteImage = (id) => {
    const arr = data.image.filter((item, index) => index !== id);
    setData({
      ...data,
      image: arr,
    });
    // console.log(arr);
  };

  const handleChangeInput = (key, e) => {
    setData({
      ...data,
      [key]: e.target.value,
    });
  };

  const handleChangeInputWithValidation = (key, validationKey, e) => {
    if (e.target.value.trim().length >= 3) {
      setData({
        ...data,
        [key]: e.target.value,
        [validationKey]: true,
      });
    } else {
      setData({
        ...data,
        [key]: e.target.value,
        [validationKey]: false,
      });
    }
  };

  const handleChangeInputVehicleType = (e) => {
    var vehicleTypeListValid = [
      "2 wheeler",
      "3 wheeler",
      "4 Wheeler",
      "Tractor",
    ];
    console.log("valid", vehicleTypeListValid.includes(e.target.value));
    if (vehicleTypeListValid.includes(e.target.value)) {
      setData({
        ...data,
        vehicle_type: e.target.value,
        check_vehicleTypeInputChange: true,
      });
    } else {
      setData({
        ...data,
        vehicle_type: e.target.value,
        check_vehicleTypeInputChange: false,
      });
    }
  };

  const handleChangeRichTextInput = (key, value) => {
    setData({
      ...data,
      [key]: value,
    });
  };

  const handleSubmit = () => {
    // console.log("user id in form", user.user.id);
    // console.log("property type id in form", data.property_type);
    NewVehicle(data, user.user.id, user.token)
      .then((res) => {
        if (res === undefined || res === null) {
          toast.error("Can't connect to server", {
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: false,
            theme: "dark",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        if (res.detail) {
          if (res.detail === "Invalid token.") {
            return toast.error("Invalid token. Please Login Again...", {
              position: "top-right",
              autoClose: 2500,
              hideProgressBar: false,
              theme: "dark",
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
          return toast.error(res.detail, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            theme: "dark",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        handleVerifyModalClose();
        toast.success(
          "Your Vehicle details successfully uploaded",
          {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
        // return res;
        return setTimeout(() => {
          window.location.reload();
          //    return navigate('/');
        }, 4000);
      })
      .catch((error) => {
        return toast.error(error, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          theme: "dark",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  const getStateList = () => {
    getStateListHelper()
      .then((res) => {
        if (res === undefined || res === null) {
          toast.error("Can't Find State List", {
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: false,
            theme: "dark",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          return setStateList({});
        }
        return setStateList(res);
      })
      .catch((err) => {
        return toast.error(err, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          theme: "dark",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  useEffect(() => {
    return getStateList();
  }, []);

  return (
    <Base>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar
        theme="dark"
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <ImageModalView
        show={modalImageShow}
        onHide={handleModalImageClose}
        modalImageLink={modalImageLink}
      />
      <OwnerModalVerify
        show={modalVerifyShow}
        onHide={handleVerifyModalClose}
        data={data}
        setData={setData}
        handleSubmit={handleSubmit}
        token={user.token}
      />
      <div className="container">
        <div class="my-5">
          <form class="needs-validation">
            <div class="row g-3">
              <div class="col-sm-12">
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio1"
                    value="Sell"
                    checked={data.vehicle_rent_sell === "Sell" ? true : false}
                    onClick={(e) => handleChangeInput("vehicle_rent_sell", e)}
                  />
                  <label class="form-check-label" for="inlineRadio1">
                    Sell my Vehicle
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio2"
                    value="Rent"
                    disabled
                    checked={data.vehicle_rent_sell === "Rent" ? true : false}
                    onClick={(e) => handleChangeInput("vehicle_rent_sell", e)}
                  />
                  <label class="form-check-label" for="inlineRadio2">
                    Rent my Vehicle
                  </label>
                </div>
              </div>
              <hr />
              <OwnerInformation
                data={data}
                setData={setData}
                user={user.user}
              />
              <hr />
              <div class="col-sm-4">
                <label for="lastName" class="form-label">
                  Vehicle Type :
                </label>
                <select
                  className={
                    data.check_vehicleTypeInputChange
                      ? "form-control is-valid"
                      : "form-control is-invalid"
                  }
                  aria-label="Default select example"
                  value={data.vehicle_type}
                  onChange={(text) => handleChangeInputVehicleType(text)}
                >
                  <option disabled value="selected">
                    Select Vehicle Type
                  </option>
                  <option value="2 wheeler">2 wheeler</option>
                  <option value="3 wheeler">3 Wheeler</option>
                  <option value="4 Wheeler">4 Wheeler</option>
                  <option value="Tractor">Tractor</option>
                </select>
                {data.check_vehicleTypeInputChange ? null : (
                  <div
                    id="validationServerNameFeedback"
                    className="invalid-feedback text-danger p-1"
                  >
                    Vehicle Type must be selected.
                  </div>
                )}
              </div>
              <div class="col-sm-4">
                <label for="firstName" class="form-label">
                  Vehicle Company Name :
                </label>
                <input
                  className={
                    data.check_nameInputChange
                      ? "form-control is-valid"
                      : "form-control is-invalid"
                  }
                  type="text"
                  name="vehicle-company_name"
                  placeholder="Eg. Duet Scooty"
                  id="vehicle-company_name"
                  value={data.company_name}
                  onChange={(text) =>
                    handleChangeInputWithValidation(
                      "company_name",
                      "check_nameInputChange",
                      text
                    )
                  }
                  required
                />
                {data.check_nameInputChange ? null : (
                  <div
                    id="validationServerNameFeedback"
                    className="invalid-feedback text-danger p-1"
                  >
                    Vehicle Company Name must not be null.
                  </div>
                )}
              </div>
              <div class="col-sm-4">
                <label for="firstName" class="form-label">
                  Vehicle Number :
                </label>
                <input
                  className={
                    data.check_vehicleNoInputChange
                      ? "form-control is-valid"
                      : "form-control is-invalid"
                  }
                  type="text"
                  name="vehicle-vehicle_number"
                  placeholder="Eg. 2 MH01AN9230"
                  id="vehicle-vehicle_number"
                  value={data.vehicle_number}
                  onChange={(text) =>
                    handleChangeInputWithValidation(
                      "vehicle_number",
                      "check_vehicleNoInputChange",
                      text
                    )
                  }
                  required
                />
                {data.check_vehicleNoInputChange ? null : (
                  <div
                    id="validationServerNameFeedback"
                    className="invalid-feedback text-danger p-1"
                  >
                    Vehicle Number must not be null.
                  </div>
                )}
              </div>
              <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
                {data.image?.map((img1, index) => (
                  <div key={index} className="col imagecontainer mx-3">
                    <img className="uploadimage" alt="..." src={img1.path} />
                    <div className="middle">
                      <button
                        type="button"
                        onClick={() => handleModalImageShow(img1.path)}
                        className="btn btn-outline-* m-2"
                        style={{ backgroundColor: "#02B290" }}
                      >
                        <BiShow size={25} />
                      </button>
                      <button
                        type="button"
                        onClick={() => handleDeleteImage(index)}
                        className="btn btn-outline-* m-2"
                        style={{ backgroundColor: "#BF3312" }}
                      >
                        <MdDelete size={25} />
                      </button>
                    </div>
                  </div>
                ))}
              </div>
              <div class="col-12">
                <div class="input-group has-validation">
                  <Button onClick={handleClickImage}>Upload Image</Button>
                  <input
                    type="file"
                    multiple
                    accept="image/*"
                    ref={hiddenFileInput}
                    onChange={handleChangeImage}
                    style={{ display: "none" }}
                  />
                  {data.image?.length !== 0 ? null : (
                    <p className="text-sm-start text-danger m-1">Compulsory</p>
                  )}
                </div>
              </div>
              <div class="col-12">
                <label for="address" class="form-label">
                  Address
                </label>
                <textarea
                  className={
                    data.check_addressLine1InputChange
                      ? "form-control is-valid"
                      : "form-control is-invalid"
                  }
                  rows={2}
                  cols={100}
                  name="property-address_line1"
                  id="property-address_line1"
                  value={data.address_line1}
                  placeholder="Eg. Shastri Nagar, Tumsar, Maharashtra 441912"
                  onChange={(text) =>
                    handleChangeInputWithValidation(
                      "address_line1",
                      "check_addressLine1InputChange",
                      text
                    )
                  }
                />
                {data.check_addressLine1InputChange ? null : (
                  <div
                    id="validationServerNameFeedback"
                    className="invalid-feedback text-danger p-1"
                  >
                    Address Line must not be null.
                  </div>
                )}
              </div>

              {/* <div class="col-12">
                <label for="address2" class="form-label">
                  Address 2 <span class="text-muted">(Optional)</span>
                </label>
                <textarea
                  className="form-control"
                  rows={2}
                  cols={100}
                  name="property-address_line2"
                  id="property-address_line2"
                  value={data.address_line2}
                  onChange={(text) => handleChangeInput("address_line2", text)}
                />
              </div> */}

              <div class="col-md-3">
                <label for="country" class="form-label">
                  City :
                </label>
                <input
                  className={
                    data.check_cityInputChange
                      ? "form-control is-valid"
                      : "form-control is-invalid"
                  }
                  type="text"
                  name="property-city"
                  id="property-city"
                  value={data.city}
                  onChange={(text) =>
                    handleChangeInputWithValidation(
                      "city",
                      "check_cityInputChange",
                      text
                    )
                  }
                  required
                />
                {data.check_cityInputChange ? null : (
                  <div
                    id="validationServerNameFeedback"
                    className="invalid-feedback text-danger p-1"
                  >
                    City Name is Compulsory.
                  </div>
                )}
              </div>

              <div class="col-md-3">
                <label for="state" class="form-label">
                  District :
                </label>
                <select
                  className="form-select form-select-sm"
                  aria-label="Default select example"
                  value={data.district}
                  onChange={(text) => handleChangeInput("district", text)}
                >
                  <option disabled value="selected">
                    Select District
                  </option>
                  {stateList[data.state]?.map((item, index) => (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  ))}
                </select>
              </div>

              <div class="col-md-3">
                <label for="zip" class="form-label">
                  State :
                </label>
                <select
                  className="form-select form-select-sm"
                  aria-label="Default select example"
                  value={data.state}
                  onChange={(text) => handleChangeInput("state", text)}
                >
                  <option disabled value="selected">
                    Select state
                  </option>
                  {Object.keys(stateList)?.map((item, index) => (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  ))}
                </select>
              </div>
              <div class="col-md-3">
                <label for="state" class="form-label">
                  Country :
                </label>
                <select
                  className="form-select form-select-sm"
                  aria-label="Default select example"
                  value={data.country}
                  onChange={(text) => handleChangeInput("country", text)}
                >
                  <option disabled value="selected">
                    Select Country
                  </option>
                  <option value="India">India</option>
                </select>
              </div>
              <div class="col-sm-4">
                <label for="firstName" class="form-label">
                  Age of Vehicle : <span class="text-muted">(Optional)</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Eg. 1 year"
                  name="property-age_of_vehicle"
                  id="property-age_of_vehicle"
                  value={data.age_of_vehicle}
                  onChange={(text) => handleChangeInput("age_of_vehicle", text)}
                />
              </div>
              <div class="col-sm-4">
                <label htmlFor="firstName" class="form-label">
                  KM Run :&nbsp;&nbsp;
                  <span class="text-muted">(KM)</span>
                </label>
                <input
                  className="form-control"
                  type="number"
                  name="property-km-run"
                  id="property-km-run"
                  value={data.run_km}
                  onChange={(text) => handleChangeInput("run_km", text)}
                  required
                />
              </div>
              <div class="col-sm-4">
                <label for="firstName" class="form-label">
                  Expected Price :
                </label>
                <input
                  className="form-control"
                  type="number"
                  name="property-expected_price"
                  id="property-expected_price"
                  value={data.expected_price}
                  onChange={(text) => handleChangeInput("expected_price", text)}
                />
              </div>
              <div class="col-12">
                <label for="address2" class="form-label">
                  Description: <span class="text-muted">(Optional)</span>
                </label>
                <CKEditor
                  editor={ClassicEditor}
                  config={editorConfiguration}
                  data={data.rich_text_description}
                  onChange={(event, editor) => {
                    // console.log(Array.from( editor.ui.componentFactory.names()));
                    const data = editor.getData();
                    handleChangeRichTextInput("rich_text_description", data);
                  }}
                />
              </div>
            </div>
            <button
              type="button"
              onClick={handlVerifyModaleShow}
              className="w-100 btn btn-dark btn-sm my-4"
              disabled={
                !data.check_ownerInputName ||
                !data.check_cityInputChange ||
                !data.check_ownerInputMobileno ||
                // !data.check_ownerInputEmail ||
                !data.check_nameInputChange ||
                !data.check_vehicleTypeInputChange ||
                !data.check_addressLine1InputChange ||
                !data.image?.length > 0
              }
            >
              Submit
            </button>
            {/* <button class="w-100 btn btn-primary btn-sm my-5" type="submit">
              Continue to checkout
            </button> */}
          </form>
        </div>
      </div>
    </Base>
  );
};

export default UploadVehicle;

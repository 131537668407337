import React from "react";
import Modal from "react-bootstrap/Modal";
import Alert from "react-bootstrap/Alert";
import { toast } from "react-toastify";
import Button from "react-bootstrap/Button";
import { sendOtpSms, verifyOtpSms } from "../../helper/SendOtpHelper";

const OwnerModalVerify = (props) => {
  const handleChangeOtp = (e) => {
    if (e.target.value.trim().length >= 6) {
      props.setData({
        ...props.data,
        otp: e.target.value,
        check_otpInputChange: true,
      });
    } else {
      props.setData({
        ...props.data,
        otp: e.target.value,
        check_otpInputChange: false,
      });
    }
  };

  const handleSubmitSendOtp = () => {
    props.setData({
      ...props.data,
      email_send_otp_loading: true,
    });
    sendOtpSms(props.data.owner_mobile_no,props.token)
      .then((res) => {
        console.log(res);
        props.setData({
            ...props.data,
            email_send_otp_loading: false,
          });
        if (res === undefined || res === null) {
          toast.error("Can't connect to server", {
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: false,
            theme: "dark",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        if (res.error) {
          return toast.error(res.error, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            theme: "dark",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        if (res.detail) {
            return toast.error(res.detail, {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              theme: "dark",
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        props.setData({
          ...props.data,
          email_send_otp_loading: false,
          email_send_success_otp: true,
        });
        // appointmentModalClose();
        return toast.success("OTP Send Successfully", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch((error) => {
        props.setData({
          ...props.data,
          loading: false,
        });
        return toast.error(error, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          theme: "dark",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  const handleSubmitOtp = () => {
    props.setData({
      ...props.data,
      email_send_otp_loading: true,
    });
    verifyOtpSms(props.data.owner_mobile_no, props.data.otp,props.token)
      .then((res) => {
        if (res.error) {
          props.setData({
            ...props.data,
            email_send_otp_loading: false,
          });
          console.log(res);
          if (res === undefined || res === null) {
            toast.error("Can't connect to server", {
              position: "top-right",
              autoClose: 2500,
              hideProgressBar: false,
              theme: "dark",
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
          return toast.error(res.error, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            theme: "dark",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        props.setData({
          ...props.data,
          email_send_otp_loading: false,
          email_send_success_otp: true,
          verify_otp: true,
        });
        props.handleSubmit()
        // appointmentModalClose();
        return toast.success("OTP Verify Successfully", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch((error) => {
        props.setData({
          ...props.data,
          email_send_otp_loading: false,
        });
        return toast.error(error, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          theme: "dark",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  return (
    <Modal {...props} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>Verify!</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {props.data.email_send_success_otp && !props.data.verify_otp && (
          <Alert variant="success">
            <p>
              We've send OTP On your mobile no for setting Verification, You
              should receive them shortly.
            </p>
          </Alert>
        )}
        <form class="row g-3">
          <div class="col-auto">
            <label for="staticEmail2" class="visually-hidden">
              Mobile No:
            </label>
            <input
              type="text"
              readonly
              class="form-control-plaintext"
              id="staticEmail2"
              value={props.data.owner_mobile_no}
            />
          </div>
          {props.data.email_send_success_otp && (
            <div class="col-auto">
              <label for="inputPassword2" class="visually-hidden">
                OTP
              </label>
              <input
                type="password"
                class="form-control"
                id="inputPassword2"
                value={props.data.otp}
                onChange={(text) => handleChangeOtp(text)}
                placeholder="Enter Your OTP Here"
              />
              {props.data.check_otpInputChange ? null : (
                <div
                  id="validationServerNameFeedback"
                  className="invalid-feedback text-danger p-1"
                >
                  OTP must not be valid.
                </div>
              )}
            </div>
          )}

          {/* <div class="d-flex justify-content-center">
          <button type="submit" class="btn btn-info btn-sm mb-3">
            Send OTP
          </button>
        </div> */}
        </form>
      </Modal.Body>
      <Modal.Footer>
        {props.data.email_send_otp_loading && (
          <Button variant="info">
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            &nbsp;&nbsp;Loading...
          </Button>
        )}

        {!props.data.email_send_success_otp &&
          !props.data.email_send_otp_loading && (
            <Button variant="dark" onClick={handleSubmitSendOtp}>
              Send OTP
            </Button>
          )}
        {props.data.email_send_success_otp &&
          !props.data.email_send_otp_loading && !props.data.verify_otp && (
            <Button variant="dark" onClick={handleSubmitOtp}>
              verify OTP
            </Button>
          )}
        {/* {props.data.verify_otp && !props.data.email_send_otp_loading && (
          <Button
            variant="primary"
            onClick={props.handleSubmit}
            disabled={
              !props.data.check_ownerInputName ||
              !props.data.check_ownerInputMobileno ||
              //   !props.data.check_ownerInputEmail ||
              !props.data.check_nameInputChange ||
              !props.data.check_propertyTypeInputChange ||
              !props.data.check_addressLine1InputChange ||
              !(
                props.data.image?.length !== 0 ||
                !(
                  props.data.property_type === 3 ||
                  props.data.property_type === 4 ||
                  props.data.property_type === 6
                )
              ) ||
              !(
                props.data.document_image?.length !== 0 ||
                !(
                  props.data.property_type === 5 ||
                  props.data.property_type === 2
                )
              )
            }
          >
            Save
          </Button>
        )} */}
      </Modal.Footer>
    </Modal>
  );
};

export default OwnerModalVerify;

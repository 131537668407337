import React, { useState, useContext } from "react";
import Button from "react-bootstrap/Button";
import { ToastContainer } from "react-toastify";
import { Link } from "react-router-dom";
import { FaLock } from "react-icons/fa";
import { AuthContext } from "../AuthProvider";
import "../css/RegisterPage.css";

const RegisterPage = () => {
  const { register } = useContext(AuthContext);

  const [data, setData] = useState({
    first_name: "",
    last_name: "",
    mobileno: "",
    email: "",
    password: "",
    check_firstInputChange: false,
    check_lastInputChange: false,
    check_emailInputChange: false,
    check_mobilenoInputChange: false,
    check_passwordInputChange: false,
    secureTextEntry: false,
  });

  const emailInputChange = (e) => {
    let reg =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (e.target.value.trim().length >= 3 && reg.test(e.target.value)) {
      setData({
        ...data,
        email: e.target.value,
        check_emailInputChange: true,
      });
    } else {
      setData({
        ...data,
        email: e.target.value,
        check_emailInputChange: false,
      });
    }
  };

  const firstNameInputChange = (e) => {
    if (e.target.value.trim().length >= 2) {
      setData({
        ...data,
        first_name: e.target.value,
        check_firstInputChange: true,
      });
    } else {
      setData({
        ...data,
        first_name: e.target.value,
        check_firstInputChange: false,
      });
    }
  };

  const lastNameInputChange = (e) => {
    if (e.target.value.trim().length >= 2) {
      setData({
        ...data,
        last_name: e.target.value,
        check_lastInputChange: true,
      });
    } else {
      setData({
        ...data,
        last_name: e.target.value,
        check_lastInputChange: false,
      });
    }
  };

  const passwordInputChange = (e) => {
    if (e.target.value.trim().length >= 3) {
      setData({
        ...data,
        password: e.target.value,
        check_passwordInputChange: true,
      });
    } else {
      setData({
        ...data,
        password: e.target.value,
        check_passwordInputChange: false,
      });
    }
  };

  const mobilenoInputChange = (e) => {
    if (e.target.value.trim().length === 10) {
      setData({
        ...data,
        mobileno: e.target.value,
        check_mobilenoInputChange: true,
      });
    } else {
      setData({
        ...data,
        mobileno: e.target.value,
        check_mobilenoInputChange: false,
      });
    }
  };

  // const updateSecureTextEntry = () => {
  //   setData({
  //     ...data,
  //     secureTextEntry: !data.secureTextEntry,
  //   });
  // };

  return (
    <div className="register-page">
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar
        theme="dark"
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="register-page-form">
        <form className="register-form">
          <div className="register-lock">
            <FaLock size={50} id="register-lock" />
          </div>
          <div className="register-input-container">
            <label>First Name </label>
            <br />
            <input
              type="text"
              name="uname"
              value={data.first_name}
              className={
                data.check_firstInputChange
                  ? "form-control is-valid"
                  : "form-control is-invalid"
              }
              onChange={(text) => firstNameInputChange(text)}
              placeholder="abcd"
              required
            />
            {data.check_firstInputChange ? null : (
              <div
                id="validationServerNameFeedback"
                className="invalid-feedback text-black p-1"
              >
                First Name must be 2 characters long.
              </div>
            )}
          </div>
          <div className="register-input-container">
            <label>Last Name </label>
            <br />
            <input
              type="text"
              name="uname"
              value={data.last_name}
              className={
                data.check_lastInputChange
                  ? "form-control is-valid"
                  : "form-control is-invalid"
              }
              onChange={(text) => lastNameInputChange(text)}
              placeholder="xyz"
              required
            />
            {data.check_lastInputChange ? null : (
              <div
                id="validationServerNameFeedback"
                className="invalid-feedback text-black p-1"
              >
                Last Name must be 2 characters long.
              </div>
            )}
          </div>
          <div className="register-input-container">
            <label>Mobile No </label>
            <br />
            <input
              type="text"
              value={data.mobileno}
              className={
                data.check_mobilenoInputChange
                  ? "form-control is-valid"
                  : "form-control is-invalid"
              }
              onChange={(text) => mobilenoInputChange(text)}
              name="mobileno"
              placeholder="mobileno"
              required
            />
            {/* <button type="button" onClick={() => updateSecureTextEntry()} className="btn btn-outline-* d-flex justify-content-end">
                <AiFillEyeInvisible size={20} />
              </button> */}

            {data.check_mobilenoInputChange ? null : (
              <div
                id="validationServerNameFeedback"
                className="invalid-feedback text-black p-1"
              >
                Mobile no must be 10 characters.
              </div>
            )}
          </div>
          <div className="register-input-container">
            <label>Email </label>
            <br />
            <input
              type="email"
              name="uname"
              value={data.email}
              className={
                data.check_emailInputChange
                  ? "form-control is-valid"
                  : "form-control is-invalid"
              }
              onChange={(text) => emailInputChange(text)}
              placeholder="xyz@gmail.com"
              required
            />
            {data.check_emailInputChange ? null : (
              <div
                id="validationServerNameFeedback"
                className="invalid-feedback text-black p-1"
              >
                Please enter a valid email address.
              </div>
            )}
          </div>
          <div className="register-input-container">
            <label>Password </label>
            <br />
            <input
              type={data.secureTextEntry ? "text" : "password"}
              value={data.password}
              className={
                data.check_passwordInputChange
                  ? "form-control is-valid"
                  : "form-control is-invalid"
              }
              onChange={(text) => passwordInputChange(text)}
              name="pass"
              placeholder="Password"
              required
            />
            {/* <button type="button" onClick={() => updateSecureTextEntry()} className="btn btn-outline-* d-flex justify-content-end">
                <AiFillEyeInvisible size={20} />
              </button> */}

            {data.check_passwordInputChange ? null : (
              <div
                id="validationServerNameFeedback"
                className="invalid-feedback text-black p-1"
              >
                Password must be 3 characters long.
              </div>
            )}
          </div>
          <div className="register-page-login-btn">
            <Button
              id="register-page-login-btn"
              disabled={
                !data.check_emailInputChange ||
                !data.check_passwordInputChange ||
                !data.check_firstInputChange ||
                !data.check_lastInputChange ||
                !data.check_mobilenoInputChange
              }
              onClick={() =>
                register(
                  data.first_name,
                  data.last_name,
                  data.email,
                  data.password,
                  data.mobileno
                )
              }
            >
              Register
            </Button>
          </div>
          <div className="for-new-user">
            <p>
              <Link to="/user/login">Login</Link>{" "}
            </p>
          </div>
        </form>
      </div>
    </div>
  );
};

export default RegisterPage;

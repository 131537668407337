import React, { useContext } from "react";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import { CgMenuGridO } from "react-icons/cg";
import { AuthContext } from "../../AuthProvider";
import "./NavigationBar.css";

const NavigationBar = () => {
  const { logout, user } = useContext(AuthContext);

  return (
    <div>
      <nav
        className="navbar fixed-top navbar-expand-lg navbar-dark bg-dark"
        aria-label="Fifth navbar example"
      >
        <div className="container-fluid">
          <Link className="navbar-brand" to="/">
            <h1>𝖕𝖔𝖈𝖔𝖓𝖊𝖘𝖙</h1>
          </Link>
          <button
            className="navbar-toggler menu-icon"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarsExample05"
            aria-controls="navbarsExample05"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            {/* <span className="navbar-toggler-icon"></span> */}
            <CgMenuGridO size={35} color="white" />
          </button>

          <div className="collapse navbar-collapse" id="navbarsExample05">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item" style={{ marginRight: "20px" }}>
                <Link className="nav-link text-white fw-bold" to="/">
                  Home
                </Link>
              </li>
              <li className="nav-item" style={{ marginRight: "20px" }}>
                <Link className="nav-link text-white fw-bold" to="/vehicle">
                  Vehicle shopping
                </Link>
              </li>
              {/* <hr style={{padding:0}} /> */}
              {user?.user?.is_staff && (
              <>
                <li className="nav-item" style={{ marginRight: "20px" }}>
                  <Link
                    to="/admin/requests"
                    className="nav-link text-white fw-bold"
                  >
                    Requests
                  </Link>
                </li>
                <li className="nav-item" style={{ marginRight: "20px" }}>
                  <Link
                    to="/admin/vehicle-requests"
                    className="nav-link text-white fw-bold"
                  >
                    Vehicle Requests
                  </Link>
                </li>
                </>
              )}
              {user ? (
                <li className="nav-item" style={{ marginRight: "20px" }}>
                  <Link
                    to="/upload/property"
                    className="nav-link text-white fw-bold"
                  >
                    Sell Property
                  </Link>
                </li>
              ) : (
                <li className="nav-item" style={{ marginRight: "20px" }}>
                  <Link
                    to="/user/login"
                    className="nav-link text-white fw-bold"
                  >
                    Sell Property
                  </Link>
                </li>
              )}
              {user ? (
                <li className="nav-item" style={{ marginRight: "20px" }}>
                  <Link
                    to="/upload/vehicle"
                    className="nav-link text-white fw-bold"
                  >
                    Sell Vehicle
                  </Link>
                </li>
              ) : (
                <li className="nav-item" style={{ marginRight: "20px" }}>
                  <Link
                    to="/user/login"
                    className="nav-link text-white fw-bold"
                  >
                    Sell Vehicle
                  </Link>
                </li>
              )}
              {user ? (
                <li className="nav-item">
                  <Button
                    onClick={() => logout()}
                    id="login-btn"
                    className="nav-link mt-1"
                  >
                    LOGOUT
                  </Button>
                </li>
              ) : (
                <li className="nav-item">
                  <Link to="/user/login" style={{ textDecoration: "none" }}>
                    <Button id="login-btn" className="nav-link mt-1">
                      LOGIN
                    </Button>
                  </Link>
                </li>
              )}
            </ul>
          </div>
        </div>
      </nav>
      {/* NAVIGATION BAR */}
      {/* <div classNameName="navbar">
        <div classNameName="left-navbar flex-1">
          <div classNameName="brand-name">
            <h1>POCONEST</h1> */}
      {/* <img src={logo} alt=""/> */}
      {/* </div> */}
      {/* <div>
                <h1 classNameName="navbar-brand">Prerna Eye Care</h1>
                </div>    */}
      {/* </div>
        <div classNameName="right-navbar">
          <Link to="/" classNameName="text-link ">
            HOME
          </Link>
          {user ? (
            <Link to="/propertyuploadform" classNameName="text-link ">
              Upload
            </Link>
          ) : (
            <Link to="/propertyuploadform" classNameName="text-link ">
              Upload
            </Link>
          )}
          {user ? (
            <Button onClick={() => logout()} id="login-btn">
              LOGOUT
            </Button>
          ) : (
            <Link to="/user/login">
              <Button id="login-btn">LOGIN</Button>
            </Link>
          )}
        </div>
      </div> */}
    </div>
  );
};

export default NavigationBar;

import React, { useState, useRef, useEffect, useContext } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Button from "react-bootstrap/Button";
import { ToastContainer, toast } from "react-toastify";
import { BiShow } from "react-icons/bi";
import { MdDelete } from "react-icons/md";
import { AuthContext } from "../AuthProvider";
import editorConfiguration from "../helper/editorConfiguration";
import { NewProperty } from "../helper/UploadProperty";
import { getStateListHelper } from "../helper/CoreAPI";
import { getPropertyTypeList } from "../helper/PropertyType";
import Base from "../component/Base/Base";
import OwnerInformation from "../component/PropertyUploadForm/OwnerInformation";
import ImageModalView from "../component/PropertyUploadForm/ImageModalView";
import OwnerModalVerify from "../component/PropertyUploadForm/OwnerModalVerify";
import "../css/PropertyUploadForm.css";

const PropertyUploadForm = () => {
  const { user } = useContext(AuthContext);
  const [propertyTypeList, setPropertyTypeList] = useState([]);
  const [stateList, setStateList] = useState({});
  const [data, setData] = useState({
    property_rent_sell: "Sell",
    name: "",
    owner_name: "",
    owner_mobile_no: "",
    owner_email_id: "",
    property_type: "selected",
    image: [],
    document_image: [],
    floor_number: "",
    address_no: "",
    address_line1: "",
    address_line2: "",
    city: "Tumsar",
    district: "Bhandara",
    state: "Maharashtra",
    country: "India",
    geo_location1: {
      lat: 0,
      lng: 0,
    },
    geo_location: "",
    google_address: "",
    description: "",
    rich_text_description: "",
    bhk_type: "No BHK",
    size: "",
    rate_price: "",
    avg_price: "",
    rent_price: "",
    security_price: "",
    possession_status: "",
    brokerage: "",
    maintenance: "",
    furnishing: "",
    age_of_property: "",
    parking: "",
    otp: "",
    email_send_otp_loading: false,
    email_send_success_otp: false,
    verify_otp: false,
    check_otpInputChange: false,
    check_nameInputChange: false,
    check_cityInputChange: true,
    check_ownerInputName: false,
    check_ownerInputMobileno: false,
    check_ownerMobilenoVerified: false,
    check_ownerInputEmail: false,
    check_propertyTypeInputChange: false,
    check_addressLine1InputChange: false,
  });

  const [modalImageShow, setModalShow] = useState(false);
  const [modalVerifyShow, setModalVerifyShow] = useState(false);
  const [modalImageLink, setModalImageLink] = useState("");

  const handleVerifyModalClose = () => setModalVerifyShow(false);
  const handlVerifyModaleShow = () => setModalVerifyShow(true);

  const handleModalImageClose = () => {
    setModalImageLink("");
    setModalShow(false);
  };
  // console.log(propertyTypeList);

  const handleModalImageShow = (image) => {
    setModalImageLink(image);
    setModalShow(true);
  };

  const hiddenFileInput = useRef(null);

  const handleClickImage = (event) => {
    hiddenFileInput.current.click();
  };
  const handleChangeImage = (event) => {
    const fileUploaded = Object.values(event.target.files);
    var images_array = data.image;
    // console.log("values",Object.values(data.image))
    // console.log("files upload",Object.values(fileUploaded))
    // console.log("length array",images_array.length)
    for (const file1 of fileUploaded) {
      var file2 = file1;
      file2["path"] = URL.createObjectURL(file1);
      images_array.push(file2);
    }
    setData({
      ...data,
      image: images_array,
    });
  };

  const handleDeleteImage = (id) => {
    const arr = data.image.filter((item, index) => index !== id);
    setData({
      ...data,
      image: arr,
    });
    // console.log(arr);
  };

  const hiddenFileInput1 = useRef(null);

  const handleClickImage1 = (event) => {
    hiddenFileInput1.current.click();
  };
  const handleChangeImage1 = (event) => {
    const fileUploaded = Object.values(event.target.files);
    var images_array = data.document_image;
    // console.log("values",Object.values(data.image))
    // console.log("files upload",Object.values(fileUploaded))
    // console.log("length array",images_array.length)
    for (const file1 of fileUploaded) {
      var file2 = file1;
      file2["path"] = URL.createObjectURL(file1);
      images_array.push(file2);
    }
    setData({
      ...data,
      document_image: images_array,
    });
  };

  const handleDeleteImage1 = (id) => {
    const arr = data.document_image.filter((item, index) => index !== id);
    setData({
      ...data,
      document_image: arr,
    });
    // console.log(arr);
  };

  const getPropertyTypeListHelper = () => {
    getPropertyTypeList()
      .then((res) => {
        if (res === undefined || res === null) {
          toast.error("Can't connect to server", {
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: false,
            theme: "dark",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          return setPropertyTypeList([]);
        }
        if (res.detail) {
          if (res.detail === "Invalid token.") {
            setPropertyTypeList([]);
            return toast.error("Invalid token. Please Login Again...", {
              position: "top-right",
              autoClose: 2500,
              hideProgressBar: false,
              theme: "dark",
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
          setPropertyTypeList([]);
          return toast.error(res.detail, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            theme: "dark",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        // console.log(res)
        return setPropertyTypeList(res);
      })
      .catch((err) => {
        return toast.error(err, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          theme: "dark",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  const handleChangeInput = (key, e) => {
    setData({
      ...data,
      [key]: e.target.value,
    });
  };

  const handleChangeInputWithValidation = (key, validationKey, e) => {
    if (e.target.value.trim().length >= 3) {
      setData({
        ...data,
        [key]: e.target.value,
        [validationKey]: true,
      });
    } else {
      setData({
        ...data,
        [key]: e.target.value,
        [validationKey]: false,
      });
    }
  };

  const handleChangeInputPropertyType = (e) => {
    var propertyTypeListValid = [];
    propertyTypeList?.map((item) => {
      propertyTypeListValid.push(item.id);
    });
    // console.log("valid",propertyTypeListValid);
    if (propertyTypeListValid.includes(parseInt(e.target.value))) {
      setData({
        ...data,
        property_type: parseInt(e.target.value),
        check_propertyTypeInputChange: true,
      });
    } else {
      setData({
        ...data,
        property_type: parseInt(e.target.value),
        check_propertyTypeInputChange: false,
      });
    }
  };

  const handleChangeRichTextInput = (key, value) => {
    setData({
      ...data,
      [key]: value,
    });
  };

  const handleSubmit = () => {
    // console.log("user id in form", user.user.id);
    // console.log("property type id in form", data.property_type);
    NewProperty(data, user.user.id, user.token)
      .then((res) => {
        if (res === undefined || res === null) {
          toast.error("Can't connect to server", {
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: false,
            theme: "dark",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        if (res.detail) {
          if (res.detail === "Invalid token.") {
            return toast.error("Invalid token. Please Login Again...", {
              position: "top-right",
              autoClose: 2500,
              hideProgressBar: false,
              theme: "dark",
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
          return toast.error(res.detail, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            theme: "dark",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        handleVerifyModalClose();
        toast.success(
          "Your property details successfully submitted.your property will be available after verify",
          {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
        // return res;
        return setTimeout(() => {
          window.location.reload();
          //    return navigate('/');
        }, 4000);
      })
      .catch((error) => {
        return toast.error(error, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          theme: "dark",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  // function getPositionsInMap(position) {
  //   const latitude = position.coords.latitude;
  //   const longitude = position.coords.longitude;
  //   setData({
  //     ...data,
  //     geo_location: {
  //       lat: latitude,
  //       lng: longitude,
  //     },
  //   });
  //   // x.innerHTML =
  //   //   "Latitude: " +
  //   //   position.coords.latitude +
  //   //   "<br>Longitude: " +
  //   //   position.coords.longitude;
  // }

  // function showErrorGetPositionsInMap(error) {
  //   if (error.PERMISSION_DENIED) {
  //     toast.error("User denied the request for Geolocation.", {
  //       position: "top-right",
  //       autoClose: 2000,
  //       hideProgressBar: false,
  //       theme: "dark",
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //     });
  //   } else if (error.POSITION_UNAVAILABLE) {
  //     toast.error("Location information is unavailable.", {
  //       position: "top-right",
  //       autoClose: 2000,
  //       hideProgressBar: false,
  //       theme: "dark",
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //     });
  //   } else if (error.TIMEOUT) {
  //     toast.error("The request to get user location timed out.", {
  //       position: "top-right",
  //       autoClose: 2000,
  //       hideProgressBar: false,
  //       theme: "dark",
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //     });
  //   } else {
  //     toast.error("An unknown error occurred.", {
  //       position: "top-right",
  //       autoClose: 2000,
  //       hideProgressBar: false,
  //       theme: "dark",
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //     });
  //   }

    // switch(error.code) {
    //   case error.PERMISSION_DENIED:
    //     x.innerHTML = "User denied the request for Geolocation."
    //     break;
    //   case error.POSITION_UNAVAILABLE:
    //     x.innerHTML = "Location information is unavailable."
    //     break;
    //   case error.TIMEOUT:
    //     x.innerHTML = "The request to get user location timed out."
    //     break;
    //   case error.UNKNOWN_ERROR:
    //     x.innerHTML = "An unknown error occurred."
    //     break;
    // }
//  }

  const getStateList = () => {
    getStateListHelper()
      .then((res) => {
        if (res === undefined || res === null) {
          toast.error("Can't Find State List", {
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: false,
            theme: "dark",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          return setStateList({});
        }
        return setStateList(res);
      })
      .catch((err) => {
        return toast.error(err, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          theme: "dark",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  useEffect(() => {
    // if (navigator.geolocation) {
    //   navigator.geolocation.getCurrentPosition(
    //     getPositionsInMap,
    //     showErrorGetPositionsInMap
    //   );
    // } else {
    //   toast.error("Geolocation is not supported by this browser.", {
    //     position: "top-right",
    //     autoClose: 2000,
    //     hideProgressBar: false,
    //     theme: "dark",
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //   });
    // }
    getStateList();
    return getPropertyTypeListHelper();
  }, []);

  return (
    <Base>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar
        theme="dark"
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <ImageModalView
        show={modalImageShow}
        onHide={handleModalImageClose}
        modalImageLink={modalImageLink}
      />
      <OwnerModalVerify
        show={modalVerifyShow}
        onHide={handleVerifyModalClose}
        data={data}
        setData={setData}
        handleSubmit={handleSubmit}
        token={user.token}
      />

      <div className="container">
        <div class="my-5">
          <form class="needs-validation">
            <div class="row g-3">
              <div class="col-sm-12">
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio1"
                    value="Sell"
                    checked={data.property_rent_sell === "Sell" ? true : false}
                    onClick={(e) => handleChangeInput("property_rent_sell", e)}
                  />
                  <label class="form-check-label" for="inlineRadio1">
                    Sell my property
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio2"
                    value="Rent"
                    checked={data.property_rent_sell === "Rent" ? true : false}
                    onClick={(e) => handleChangeInput("property_rent_sell", e)}
                  />
                  <label class="form-check-label" for="inlineRadio2">
                    Rent my property
                  </label>
                </div>
              </div>
              <hr />
              <OwnerInformation
                data={data}
                setData={setData}
                user={user.user}
              />
              <hr />
              <div class="col-sm-6">
                <label for="firstName" class="form-label">
                  Property Name :
                </label>
                <input
                  className={
                    data.check_nameInputChange
                      ? "form-control is-valid"
                      : "form-control is-invalid"
                  }
                  type="text"
                  name="property-name"
                  placeholder="Eg. 2 BHK Flat"
                  id="property-name"
                  value={data.name}
                  onChange={(text) =>
                    handleChangeInputWithValidation(
                      "name",
                      "check_nameInputChange",
                      text
                    )
                  }
                  required
                />
                {data.check_nameInputChange ? null : (
                  <div
                    id="validationServerNameFeedback"
                    className="invalid-feedback text-danger p-1"
                  >
                    Property Name must not be null.
                  </div>
                )}
              </div>
              <div class="col-sm-6">
                <label for="lastName" class="form-label">
                  Property Type :
                </label>
                <select
                  className={
                    data.check_propertyTypeInputChange
                      ? "form-control is-valid"
                      : "form-control is-invalid"
                  }
                  aria-label="Default select example"
                  value={data.property_type}
                  onChange={(text) => handleChangeInputPropertyType(text)}
                >
                  <option disabled value="selected">
                    Select Property Type
                  </option>
                  {propertyTypeList?.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.sub_type}
                    </option>
                  ))}
                </select>
                {data.check_propertyTypeInputChange ? null : (
                  <div
                    id="validationServerNameFeedback"
                    className="invalid-feedback text-danger p-1"
                  >
                    Property Type must be selected.
                  </div>
                )}
              </div>
              <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
                {data.image?.map((img1, index) => (
                  <div key={index} className="col imagecontainer mx-3">
                    <img className="uploadimage" alt="..." src={img1.path} />
                    <div className="middle">
                      <button
                        type="button"
                        onClick={() => handleModalImageShow(img1.path)}
                        className="btn btn-outline-* m-2"
                        style={{ backgroundColor: "#02B290" }}
                      >
                        <BiShow size={25} />
                      </button>
                      <button
                        type="button"
                        onClick={() => handleDeleteImage(index)}
                        className="btn btn-outline-* m-2"
                        style={{ backgroundColor: "#BF3312" }}
                      >
                        <MdDelete size={25} />
                      </button>
                    </div>
                  </div>
                ))}
              </div>
              <div class="col-12">
                <div class="input-group has-validation">
                  <Button onClick={handleClickImage}>Upload Image</Button>
                  <input
                    type="file"
                    multiple
                    accept="image/*"
                    ref={hiddenFileInput}
                    onChange={handleChangeImage}
                    style={{ display: "none" }}
                  />
                  {data.image?.length !== 0 ||
                  !(
                    data.property_type === 3 ||
                    data.property_type === 4 ||
                    data.property_type === 6 ||
                    data.property_type === 10
                  ) ? null : (
                    <p className="text-sm-start text-danger m-1">Compulsory</p>
                  )}
                </div>
              </div>
              <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
                {data.document_image?.map((img1, index) => (
                  <div key={index} className="col imagecontainer mx-3">
                    <img className="uploadimage" alt="..." src={img1.path} />
                    <div className="middle">
                      <button
                        type="button"
                        onClick={() => handleModalImageShow(img1.path)}
                        className="btn btn-outline-* m-2"
                        style={{ backgroundColor: "#02B290" }}
                      >
                        <BiShow size={25} />
                      </button>
                      <button
                        type="button"
                        onClick={() => handleDeleteImage1(index)}
                        className="btn btn-outline-* m-2"
                        style={{ backgroundColor: "#BF3312" }}
                      >
                        <MdDelete size={25} />
                      </button>
                    </div>
                  </div>
                ))}
              </div>
              {data.property_type === 5 || data.property_type === 2 ? (
                <div class="col-12">
                  <div class="input-group has-validation">
                    <Button onClick={handleClickImage1}>Upload 7/12</Button>
                    <input
                      type="file"
                      multiple
                      accept="image/*"
                      ref={hiddenFileInput1}
                      onChange={handleChangeImage1}
                      style={{ display: "none" }}
                    />
                    {data.document_image?.length !== 0 ||
                    !(
                      data.property_type === 5 || data.property_type === 2
                    ) ? null : (
                      <p className="text-sm-start text-danger m-1">
                        Compulsory
                      </p>
                    )}
                  </div>
                </div>
              ) : null}
              <div class="col-sm-4">
                <label for="firstName" class="form-label">
                  Plot Number :
                </label>
                <input
                  className="form-control"
                  type="text"
                  name="property-address_no"
                  id="property-address_no"
                  value={data.address_no}
                  placeholder="Eg. 92"
                  onChange={(text) => handleChangeInput("address_no", text)}
                />
              </div>
              {data.property_type === 3 && (
                <div class="col-sm-4">
                  <label for="firstName" class="form-label">
                    Floor Number : <span class="text-muted">(Optional)</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    name="property-floor_number"
                    id="property-floor_number"
                    value={data.floor_number}
                    onChange={(text) => handleChangeInput("floor_number", text)}
                  />
                </div>
              )}
              {(data.property_type === 3 || data.property_type === 4) && (
                <div class="col-sm-4">
                  <label for="firstName" class="form-label">
                    BHK Type : <span class="text-muted">(Optional)</span>
                  </label>
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    value={data.bhk_type}
                    onChange={(text) => handleChangeInput("bhk_type", text)}
                  >
                    <option disabled value="selected">
                      BHK Type
                    </option>
                    <option value="No BHK">No BHK</option>
                    <option value="1 BHK">1 BHK</option>
                    <option value="2 BHK">2 BHK</option>
                    <option value="3 BHK">3 BHK</option>
                    <option value="4 BHK">Other BHK</option>
                  </select>
                </div>
              )}
              <div class="col-12">
                <label for="address" class="form-label">
                  Address
                </label>
                <textarea
                  className={
                    data.check_addressLine1InputChange
                      ? "form-control is-valid"
                      : "form-control is-invalid"
                  }
                  rows={2}
                  cols={100}
                  name="property-address_line1"
                  id="property-address_line1"
                  value={data.address_line1}
                  placeholder="Eg. Shastri Nagar, Tumsar, Maharashtra 441912"
                  onChange={(text) =>
                    handleChangeInputWithValidation(
                      "address_line1",
                      "check_addressLine1InputChange",
                      text
                    )
                  }
                />
                {data.check_addressLine1InputChange ? null : (
                  <div
                    id="validationServerNameFeedback"
                    className="invalid-feedback text-danger p-1"
                  >
                    Address Line must not be null.
                  </div>
                )}
              </div>

              {/* <div class="col-12">
                <label for="address2" class="form-label">
                  Address 2 <span class="text-muted">(Optional)</span>
                </label>
                <textarea
                  className="form-control"
                  rows={2}
                  cols={100}
                  name="property-address_line2"
                  id="property-address_line2"
                  value={data.address_line2}
                  onChange={(text) => handleChangeInput("address_line2", text)}
                />
              </div> */}

              <div class="col-md-3">
                <label for="country" class="form-label">
                  City :
                </label>
                <input
                  className={
                    data.check_cityInputChange
                      ? "form-control is-valid"
                      : "form-control is-invalid"
                  }
                  type="text"
                  name="property-city"
                  id="property-city"
                  value={data.city}
                  onChange={(text) =>
                    handleChangeInputWithValidation(
                      "city",
                      "check_cityInputChange",
                      text
                    )
                  }
                  required
                />
                {data.check_cityInputChange ? null : (
                  <div
                    id="validationServerNameFeedback"
                    className="invalid-feedback text-danger p-1"
                  >
                    City Name is Compulsory.
                  </div>
                )}
              </div>

              <div class="col-md-3">
                <label for="state" class="form-label">
                  District :
                </label>
                <select
                  className="form-select form-select-sm"
                  aria-label="Default select example"
                  value={data.district}
                  onChange={(text) => handleChangeInput("district", text)}
                >
                  <option disabled value="selected">
                    Select District
                  </option>
                  {stateList[data.state]?.map((item, index) => (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  ))}
                </select>
              </div>

              <div class="col-md-3">
                <label for="zip" class="form-label">
                  State :
                </label>
                <select
                  className="form-select form-select-sm"
                  aria-label="Default select example"
                  value={data.state}
                  onChange={(text) => handleChangeInput("state", text)}
                >
                  <option disabled value="selected">
                    Select state
                  </option>
                  {Object.keys(stateList)?.map((item, index) => (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  ))}
                </select>
              </div>
              <div class="col-md-3">
                <label for="state" class="form-label">
                  Country :
                </label>
                <select
                  className="form-select form-select-sm"
                  aria-label="Default select example"
                  value={data.country}
                  onChange={(text) => handleChangeInput("country", text)}
                >
                  <option disabled value="selected">
                    Select Country
                  </option>
                  <option value="India">India</option>
                </select>
              </div>
              {/* <div
                className="col-12"
                style={{ height: "100vh", width: "100%" }}
              >
                <GoogleMapReact
                  bootstrapURLKeys={{
                    key: "AIzaSyDk8DVD8Iyc_6aDIIsUPvOOASqHBtpEk7Q",
                  }}
                  defaultCenter={{
                    lat: data.geo_location.lat,
                    lng: data.geo_location.lng,
                  }}
                  defaultZoom={11}
                >
                  /* <AnyReactComponent
                    lat={59.955413}
                    lng={30.337844}
                    text="My Marker"
                  /> *****************
                </GoogleMapReact>
              </div> */}
              <div class="col-12">
                <label for="address2" class="form-label">
                  Location Coordinates:{" "}
                  <span class="text-muted">(Optional)</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  name="property-geo_location"
                  id="property-geo_location"
                  value={data.geo_location}
                  placeholder="Eg. 21.380840, 79.745598"
                  onChange={(text) => handleChangeInput("geo_location", text)}
                />
              </div>
              <div class="col-12">
                <label for="address2" class="form-label">
                  Google Map Address: <span class="text-muted">(Optional)</span>
                </label>
                <textarea
                  rows={2}
                  cols={100}
                  className="form-control"
                  name="property-google_address"
                  placeholder="Eg. https://goo.gl/maps/d31ppFfLyzb5DSsn8"
                  id="property-google_address"
                  value={data.google_address}
                  onChange={(text) => handleChangeInput("google_address", text)}
                />
              </div>
              <div class="col-sm-4">
                <label htmlFor="firstName" class="form-label">
                  Total Area :&nbsp;&nbsp;
                  <span class="text-muted">(Sq.ft)</span>
                </label>
                <input
                  className="form-control"
                  type="number"
                  name="property-size"
                  id="property-size"
                  value={data.size}
                  onChange={(text) => handleChangeInput("size", text)}
                  required
                />
              </div>
              {data.property_rent_sell === "Sell" ? (
                <>
                  <div class="col-sm-4">
                    <label for="firstName" class="form-label">
                      Rate :
                    </label>
                    <input
                      className="form-control"
                      type="number"
                      name="property-rate_price"
                      id="property-rate_price"
                      value={data.rate_price}
                      onChange={(text) => handleChangeInput("rate_price", text)}
                    />
                  </div>
                  <div class="col-sm-4">
                    Per sq.ft rate : <span class="text-muted">(Optional)</span>
                    <label for="firstName" class="form-label"></label>
                    <input
                      className="form-control"
                      type="number"
                      name="property-avg_price"
                      id="property-avg_price"
                      value={data.avg_price}
                      onChange={(text) => handleChangeInput("avg_price", text)}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div class="col-sm-4">
                    <label for="firstName" class="form-label">
                      Rent Price : <span class="text-muted">(Per Month)</span>
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      name="property-rent_price"
                      id="property-rent_price"
                      value={data.rent_price}
                      onChange={(text) => handleChangeInput("rent_price", text)}
                    />
                  </div>
                  <div class="col-sm-4">
                    Security Price : <span class="text-muted">(Optional)</span>
                    <label for="firstName" class="form-label"></label>
                    <input
                      className="form-control"
                      type="text"
                      name="property-security_price"
                      id="property-security_price"
                      value={data.security_price}
                      onChange={(text) =>
                        handleChangeInput("security_price", text)
                      }
                    />
                  </div>
                </>
              )}

              {/* <div class="col-sm-6">
                <label for="firstName" class="form-label">
                  Security Price :
                </label>
                <input
                  className="form-control"
                  type="text"
                  name="property-security_price"
                  id="property-security_price"
                  value={data.security_price}
                  onChange={(text) => handleChangeInput("security_price", text)}
                />
              </div>
              <div class="col-sm-6">
                <label for="firstName" class="form-label">
                  Maintenance Price :
                </label>
                <input
                  className="form-control"
                  type="text"
                  name="property-maintenance"
                  id="property-maintenance"
                  value={data.maintenance}
                  onChange={(text) => handleChangeInput("maintenance", text)}
                />
              </div> */}
              <div class="col-sm-6">
                <label for="firstName" class="form-label">
                  Age of Property : <span class="text-muted">(Optional)</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Eg. 1 year"
                  name="property-age_of_property"
                  id="property-age_of_property"
                  value={data.age_of_property}
                  onChange={(text) =>
                    handleChangeInput("age_of_property", text)
                  }
                />
              </div>
              <div class="col-sm-6">
                <label for="firstName" class="form-label">
                  Parking : <span class="text-muted">(Optional)</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Eg. 2 Open Parking"
                  name="property-parking"
                  id="property-parking"
                  value={data.parking}
                  onChange={(text) => handleChangeInput("parking", text)}
                />
              </div>
              <div class="col-12">
                <label for="address2" class="form-label">
                  Description: <span class="text-muted">(Optional)</span>
                </label>
                <CKEditor
                  editor={ClassicEditor}
                  config={editorConfiguration}
                  data={data.rich_text_description}
                  onChange={(event, editor) => {
                    // console.log(Array.from( editor.ui.componentFactory.names()));
                    const data = editor.getData();
                    handleChangeRichTextInput("rich_text_description", data);
                  }}
                />
              </div>
            </div>
            <button
              type="button"
              onClick={handlVerifyModaleShow}
              className="w-100 btn btn-dark btn-sm my-4"
              disabled={
                !data.check_ownerInputName ||
                !data.check_cityInputChange ||
                !data.check_ownerInputMobileno ||
                // !data.check_ownerInputEmail ||
                !data.check_nameInputChange ||
                !data.check_propertyTypeInputChange ||
                !data.check_addressLine1InputChange ||
                !(
                  data.image?.length !== 0 ||
                  !(
                    data.property_type === 3 ||
                    data.property_type === 4 ||
                    data.property_type === 6 ||
                    data.property_type === 10
                  )
                ) ||
                !(
                  data.document_image?.length !== 0 ||
                  !(data.property_type === 5 || data.property_type === 2)
                )
              }
            >
              Submit
            </button>
            {/* <button class="w-100 btn btn-primary btn-sm my-5" type="submit">
              Continue to checkout
            </button> */}
          </form>
        </div>
      </div>
    </Base>
  );
};

export default PropertyUploadForm;

import React from "react";
import Modal from "react-bootstrap/Modal";

const ImageModalView = (props) => {
  return (
    <Modal {...props} size="lg" centered>
      <Modal.Header className="bg-dark" closeButton>
        {/* <CloseButton
          onClick={props.handleModalImageClose}
          variant="white"
        /> */}
        {/* <Modal.Title>Image</Modal.Title> */}
      </Modal.Header>
      <Modal.Body className="bg-dark">
        <div className="container text-center">
          <img src={props.modalImageLink} alt="..." width={'100%'} />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ImageModalView;
